import React from "react";
import { Form, Field, withFormik } from "formik";
import _ from "lodash";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";

export const AddPaymentTypeForm = withFormik({
  mapPropsToValues: () => ({
    type: "",
  }),
  handleSubmit: (values, { props }) => {
    const trimmedType = values.type.trim();
    const formData = {
      type: trimmedType,
      id: trimmedType ? _.camelCase(trimmedType) : null,
    };
    props.onSubmit(formData);
  },
})((props) => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Payment Type
          </Text>
          <Row wrap gap="18px">
            <Field component={TextField} name="type" label="Type" />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
            >
              Save
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
