import { Field, Form, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { Text } from "components/ui/new/Text";

import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { CRUD_DATAPATH_TABLE_INFO } from "api/crud";

export const EditTableItemForm = withFormik({
  mapPropsToValues: ({ item }) => item,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(({ title, item, idFields, dataPath, isEdit = true }) => {
  const customFields = Object.keys(
    CRUD_DATAPATH_TABLE_INFO[dataPath].customFields || {}
  );
  const standardFields = Object.keys(item).filter(
    (k) => !customFields.includes(k)
  );

  const idFieldNames = idFields.map((f) => f.name);
  const autoGeneratedIds = idFields
    .filter((f) => f.autogenerated)
    .map((f) => f.name);

  const { readOnly = [] } = CRUD_DATAPATH_TABLE_INFO[dataPath];

  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            {title}
          </Text>
          <Row wrap gap="12px">
            {standardFields.map((k) => (
              <Field
                disabled={
                  readOnly.includes(k) ||
                  (idFieldNames.includes(k) &&
                    (autoGeneratedIds.includes(k) || isEdit))
                }
                key={k}
                component={TextField}
                name={k}
                label={k.replaceAll("_", " ")}
              />
            ))}
          </Row>
          {customFields.map(
            (k) => CRUD_DATAPATH_TABLE_INFO[dataPath].customFields[k].component
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
