import React, { useEffect, useState } from "react";

import { client } from "api";
import { getFacilityTypes } from "api/facilityTypes";
import { Page } from "pages/Page";
import { AddVerticalContentForm } from "components/verticalContent/AddVerticalContentForm";

const addVerticalContent = async (values) => {
  try {
    await client.post(`verticalContent/add`, values);
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    const errText = err.response.data.includes(
      "vertical_content-facility_type-app_location"
    )
      ? `Duplicate entry already exists for facility_type = ${values.facility_type} and app_location = ${values.app_location}`
      : err.response.data;
    window.alert(`Error adding Vertical Content: ${errText}`);
  }
};

export const AddVerticalContentPage = () => {
  const [facilityTypes, setFacilityTypes] = useState(null);

  useEffect(() => {
    getFacilityTypes().then(setFacilityTypes);
  }, []);

  if (!facilityTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <AddVerticalContentForm
        facilityTypes={facilityTypes}
        onSubmit={addVerticalContent}
      />
    </Page>
  );
};
