import React, { useEffect, useState } from "react";
import { Form, withFormik } from "formik";
import * as Yup from "yup";

import { Column } from "components/layout";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import {
  buildFacilityFields,
  useFacilityFields,
} from "hooks/facilityDetailsHooks";

export const EditFacilityForm = withFormik({
  mapPropsToValues: ({ clinic }) => ({
    ...clinic,
    direct_billing_providers: clinic.direct_billing_providers
      ? JSON.parse(clinic.direct_billing_providers)
      : [],
    payment_types: clinic.payment_types ? JSON.parse(clinic.payment_types) : [],
    extra_locations: clinic.extra_locations
      ? clinic.extra_locations.split(",")
      : [],
  }),
  handleSubmit: (values, { props }) => {
    let newValues = { ...values };
    if (newValues.direct_billing_providers) {
      newValues.direct_billing_providers = newValues.direct_billing_providers
        .length
        ? JSON.stringify(newValues.direct_billing_providers)
        : null;
    }
    if (newValues.payment_types) {
      newValues.payment_types = newValues.payment_types.length
        ? JSON.stringify(newValues.payment_types)
        : null;
    }

    props.onSubmit(newValues);
  },
  validationSchema: (props) =>
    Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      wait_time_for: Yup.string().nullable().when("facility_type", {
        is: "clinic",
        then: Yup.string().required(),
      }),
      initial_consultation_price_min: Yup.number()
        .nullable(true)
        .lessThan(
          Yup.ref("initial_consultation_price_max"),
          "initial_consultation_price_max must be greater than initial_consultation_price_min"
        ),
      facility_type: Yup.string().required(),
      secondary_type: Yup.string().nullable().when("facility_type", {
        is: "clinic",
        then: Yup.string().required(),
      }),
      bundle_id: Yup.string().required(),
      visibility: Yup.string().required(),
      average_rating: Yup.number()
        .nullable()
        .test("is-required", "required", function (value) {
          const { review_count, source } = this.parent;
          return !(!value && (review_count || source));
        }),
      review_count: Yup.number()
        .nullable()
        .test("is-required", "required", function (value) {
          const { average_rating, source } = this.parent;
          return !(!value && (average_rating || source));
        }),
      rating_source: Yup.string()
        .nullable()
        .test("is-required", "required", function (value) {
          const { average_rating, review_count } = this.parent;
          return !(!value && (average_rating || review_count));
        }),
    }),
})((props) => {
  const { clinic, values, handleChange, setFieldValue } = props;

  const [selectedFacilityType, setSelectedFacilityType] = useState(
    clinic.facility_type
  );

  const fields = useFacilityFields(clinic, values);

  const disabledFields = [];
  if (clinic.id) {
    disabledFields.push("id");
    disabledFields.push("bundle_id");
    disabledFields.push("stripe_customer_id");
  }
  if (!values.parent_id) {
    disabledFields.push("parent_pays_subscription");
    disabledFields.push("parent_pays_leads");
  }

  useEffect(() => {
    if (!values.parent_id) {
      setFieldValue("parent_pays_subscription", 0);
      setFieldValue("parent_pays_leads", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parent_id]);

  if (!fields) {
    return <div>Loading Clinic Form...</div>;
  }

  const {
    textFields,
    selectFields,
    multiSelectFields,
    textAreaFields,
    toggleFields,
  } = buildFacilityFields(fields, {
    selectedFacilityType,
    setSelectedFacilityType,
    handleSelectChange: handleChange,
    disabledFields,
  });

  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add/Edit Clinic
          </Text>
          <Column gap="12px">
            {textFields}
            {toggleFields}
            {selectFields}
            {multiSelectFields}
            {textAreaFields}
          </Column>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
