import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const PromotionList = (props) => {
  const { promotions } = props;
  const history = useHistory();

  const filterFunction = (promotion, filter) => {
    const lowercaseFilter = filter.toLowerCase();
    return (
      promotion.id.toLowerCase().includes(lowercaseFilter) ||
      promotion.promotion_type.toLowerCase().includes(lowercaseFilter) ||
      promotion.markdown.toLowerCase().includes(lowercaseFilter) ||
      promotion.start_date.toLowerCase().includes(lowercaseFilter) ||
      promotion.end_date.toLowerCase().includes(lowercaseFilter)
    );
  };

  const handleRowClicked = useCallback((promotion) => {
    history.push(`/promotions/${promotion.id}`);
  }, [history]);

  return (
    <DataTable
      title="Promotions"
      data={promotions}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
