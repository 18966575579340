import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { MultiSelect } from "components/ui/new/MultiSelect";

export const EditServiceForm = withFormik({
  mapPropsToValues: ({ service }) => ({
    ...service,
    facilityTypes: service.facility_types?.split(",") || [],
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})((props) => {
  const { facilityTypes } = props;
  const facilityTypeOptions = facilityTypes.map((type) => ({
    id: type.id,
    label: type.name,
  }));
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Edit Service
          </Text>
          <Row wrap gap="12px">
            <Field component={TextField} name="id" label="ID" />
            <Field
              component={TextField}
              name="service_type"
              label="Service Type"
            />
            <Field component={TextField} name="name" label="Name" />
            <Field component={TextField} name="name_short" label="Short Name" />
          </Row>
          <Field
            component={MultiSelect}
            options={facilityTypeOptions}
            name="facilityTypes"
            label="Facility Types"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
