import React from "react";

import { client } from "api";
import { Page } from "pages/Page";
import { AddFacilityFeatureOverrideForm } from "components/facilityFeatureOverrides/AddFacilityFeatureOverrideForm";

const addFacilityFeatureOverride = async (values) => {
  try {
    await client.post(`facilityFeatureOverrides/add`, values);
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    window.alert(
      `Error adding facility feature override: ${err.response.data}`
    );
  }
};

export const AddFacilityFeatureOverridePage = () => {
  return (
    <Page>
      <AddFacilityFeatureOverrideForm onSubmit={addFacilityFeatureOverride} />
    </Page>
  );
};
