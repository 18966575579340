import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: ({ align }) => align,
    justifyContent: ({ justify }) => justify,
    flexWrap: ({ wrap }) => (wrap ? 'wrap' : 'nowrap'),
    // There seems to be a bug with makeStyles that prevents &>* from working here when using props
    // Note that this issue only seems to occur once built. It does not occur in dev mode.
    '&>:not(:last-child), &>:last-child': {
      marginRight: ({ gap }) => gap,
      marginBottom: ({ wrap, gap }) => wrap && gap,
    },
    marginBottom: ({ wrap, gap }) => (wrap && gap ? `-${gap}` : undefined),
    marginRight: ({ gap }) => (gap ? `-${gap}` : undefined),
  },
}));

export const Row = React.memo(
  React.forwardRef((props, ref) => {
    const {
      gap,
      style,
      justify,
      align,
      wrap,
      className,
      component,
      ...rest
    } = props;
    const classes = useStyles({ gap, justify, align, wrap });
    const Element = component || 'div';
    return (
      <Element
        className={classNames(classes.row, className)}
        style={style}
        ref={ref}
        {...rest}
      />
    );
  })
);
