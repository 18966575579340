import React, { useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { compiler as markdownCompiler } from "markdown-to-jsx";

import { Row } from "components/layout";
import { Text } from "components/ui/new/Text";

const useStyles = makeStyles((theme) => ({
  promotionBar: {
    backgroundColor: theme.palette.new.yellow[1000],
    height: "40px",
    width: "100vw",
    zIndex: 1250,
    padding: "0 32px",
    margin: '0 -20px',
    "& a": {
      color: theme.palette.new.black[100],
      textDecoration: "underline",
    },
    "& p": {
      margin: 0,
    },
  },
  closeButton: {
    position: "absolute",
    right: 0,
  },
}));

export const PromotionBar = (props) => {
  const { promotion } = props;
  const classes = useStyles();

  const promoContent = useMemo(
    () =>
      promotion &&
      markdownCompiler(promotion.markdown, {
        wrapper: null,
        forceBlock: true,
        overrides: {
          a: {
            props: {
              target: "_blank",
              rel: "noopener",
            },
          },
        },
      }),
    [promotion]
  );

  return (
    <Row align="center" justify="center" className={classes.promotionBar}>
      <Text size="h6" mobileSize="caption" color="black100" weight={600}>
        {promoContent || ""}
      </Text>
    </Row>
  );
};
