import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { WysiwygEditor } from "components/misc/WysiwygEditor";

export const AddFaqForm = withFormik({
  mapPropsToValues: () => ({
    facility_type: "",
    index: "",
    app_location: "",
    question: "",
    answer_body: "",
  }),
  handleSubmit: (values, { props }) => {
    const formData = {
      facility_type: values?.facility_type?.trim() || null,
      index: values?.index?.trim() || null,
      app_location: values?.app_location?.trim() || 'default',
      question: values?.question?.trim() || null,
      answer_body: values?.answer_body?.trim() || null,
    };

    props.onSubmit(formData);
  },
})((props) => {
  return (
    <Column style={{ padding: "20px 120px" }}>
      <Form>
        <Column gap="12px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add a FAQ Item
          </Text>
          <Row gap="40px">
            <Field
              component={TextField}
              name="facility_type"
              label="facility_type"
            />
            <Field component={TextField} name="index" label="index" />
          </Row>
          <Row>
            <Field
              component={TextField}
              name="app_location"
              label="app_location"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={TextField}
              name="question"
              label="question"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={WysiwygEditor}
              name="answer_body"
              label="answer_body"
            />
          </Row>
          <Row style={{ alignSelf: "flex-start", marginTop: "60px" }}>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
