import { AddTableItemPage } from "pages/crud/AddTableItemPage";
import { EditTableItemPage } from "pages/crud/EditTableItemPage";
import { TablePage } from "pages/crud/TablePage";
import { Route, Switch } from "react-router-dom";

export const CrudRoutes = () => {
  return (
    <Switch>
      <Route path={`/table/:dataPath`} component={TablePage} exact />
      <Route path={`/table/:dataPath/add`} component={AddTableItemPage} exact />
      <Route
        path={`/table/:dataPath/:idValue`}
        component={EditTableItemPage}
        exact
      />
    </Switch>
  );
};
