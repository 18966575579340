import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditSponsoredListingForm } from "components/facilities/EditSponsoredListingForm";

const getSponsoredListing = async (id) => {
  const { data } = await client.get(`clinics/sponsoredListings/${id}`);
  return data.sponsoredListing;
};

const updateSponsoredListing = async (values) => {
  try {
    const { data } = await client.post(`clinics/sponsoredListings/${values.id}/update`, values)
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing sponsored listing: ${err.response.data}`);
  }
}

export const EditSponsoredListingPage = () => {
  const [sponsoredListing, setSponsoredListing] = useState(null);
  const { sponsoredListingId } = useParams();

  useEffect(() => {
    getSponsoredListing(sponsoredListingId).then(setSponsoredListing);
  }, [sponsoredListingId]);

  if (!sponsoredListing) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditSponsoredListingForm sponsoredListing={sponsoredListing} onSubmit={updateSponsoredListing} />
    </Page>
  );
};
