import { BrowserRouter } from "react-router-dom";

import { Theme } from "components/ui/Theme";
import { Routes } from "./Routes";

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Theme>
  );
}

export default App;
