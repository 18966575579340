import React from "react";

import { Page } from "pages/Page";
import { EditFacilityForm } from "components/facilities/EditFacilityForm";
import { createClinic } from "api/clinics";
import { useFacility } from "hooks/facilityDetailsHooks";

export const AddFacilityPage = () => {
  const emptyFacility = useFacility();

  if (!emptyFacility) {
    return <div>Loading Add Facility Page...</div>;
  }

  return (
    <Page>
      <EditFacilityForm clinic={emptyFacility} onSubmit={createClinic} />
    </Page>
  );
};
