import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import { Page } from "pages/Page";
import { Column } from "components/layout";
import { Button } from "components/ui/new/Button";
import { DataListComponent } from "components/tables/DataListComponent";
import {
  CRUD_DATAPATH_TABLE_INFO,
  deleteItem,
  getAllItems,
  getItemKeyPath,
} from "api/crud";
import { useColumnNames } from "hooks/useColumnNames";

export const TablePage = () => {
  const { dataPath } = useParams();
  const columnNames = useColumnNames(dataPath);
  const { pageTitle, idFields } = CRUD_DATAPATH_TABLE_INFO[dataPath];

  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    getAllItems(dataPath)
      .then(setTableData)
      .catch((e) => console.error(e));
  }, [dataPath]);

  const handleRowDelete = useCallback(
    async (item) => {
      const confirmed = window.confirm("Are you sure you want to delete?");
      if (!confirmed) {
        return;
      }
      await deleteItem(dataPath, getItemKeyPath(idFields, item));
      setTableData(tableData.filter((i) => i !== item));
    },
    [dataPath, idFields, tableData]
  );

  if (!tableData || !columnNames) {
    return <div>Loading Table...</div>;
  }

  return (
    <Page>
      <Column>
        <DataListComponent
          idFields={idFields}
          data={tableData}
          columnNames={columnNames}
          dataPath={dataPath}
          title={pageTitle}
          handleRowDelete={handleRowDelete}
        />
        <Button
          component={Link}
          to={`/table/${dataPath}/add`}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </Column>
    </Page>
  );
};
