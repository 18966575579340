import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { ServiceList } from "components/services/ServiceList";
import { Button } from "components/ui/new/Button";

const getServices = async () => {
  const { data } = await client.get("services");
  return data.services;
};

export const ServicesPage = () => {
  const [services, setServices] = useState(null);

  useEffect(() => {
    getServices().then(setServices);
  }, []);

  if (!services) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <ServiceList services={services} />
        <Button
          component={Link}
          to="/services/add"
          variant="contained"
          color="primary"
        >
          Add Service
        </Button>
      </Column>
    </Page>
  );
};
