import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const InsuranceProvidersList = (props) => {
  const { insuranceProviders } = props;
  const history = useHistory();

  const filterFunction = (insuranceProvider, filter) =>
    insuranceProvider.id.toLowerCase().includes(filter) ||
    insuranceProvider.name.toLowerCase().includes(filter);

  const handleRowClicked = useCallback((insuranceProvider) => {
    history.push(`/insuranceProviders/${insuranceProvider.id}`);
  }, []);

  return (
    <DataTable
      title="Insurance Providers"
      data={insuranceProviders}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
