import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditPaymentTypeForm } from "components/paymentTypes/EditPaymentTypeForm";

const getPaymentType = async (id) => {
  const { data } = await client.get(`paymentTypes/${id}`);
  return data.paymentType;
};

const updatePaymentType = async (values) => {
  try {
    const { data } = await client.post(
      `paymentTypes/${values.id}/update`,
      values
    );
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing payment type: ${err.response.data}`);
  }
};

export const EditPaymentTypePage = () => {
  const [paymentType, setPaymentType] = useState(null);
  const { paymentTypeId } = useParams();

  useEffect(() => {
    getPaymentType(paymentTypeId).then(setPaymentType);
  }, [paymentTypeId]);

  if (!paymentType) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditPaymentTypeForm
        paymentType={paymentType}
        onSubmit={updatePaymentType}
      />
    </Page>
  );
};
