import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { SponsoredListingList } from "components/facilities/SponsoredListingList";
import { Button } from "components/ui/new/Button";

const getSponsoredListings = async () => {
  const { data } = await client.get("clinics/sponsoredListings");
  return data.sponsoredListings;
};

export const SponsoredListingsPage = () => {
  const [sponsoredListings, setSponsoredListings] = useState(null);

  useEffect(() => {
    getSponsoredListings().then(setSponsoredListings);
  }, []);

  if (!sponsoredListings) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <SponsoredListingList sponsoredListings={sponsoredListings} />
        <Button
          component={Link}
          to="/sponsoredListings/add"
          variant="contained"
          color="primary"
        >
          Add Sponsored Listing
        </Button>
      </Column>
    </Page>
  );
};
