import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const ServiceList = (props) => {
  const { services } = props;
  const history = useHistory();

  const filterFunction = (service, filter) =>
    service.id.toLowerCase().includes(filter) ||
    service.name.toLowerCase().includes(filter);

  const handleRowClicked = useCallback((service) => {
    history.push(`/services/${service.id}`);
  }, []);

  return (
    <DataTable
      title="Services"
      data={services}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
