import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Text } from "components/ui/new/Text";
import { Column, Row } from "components/layout";
import { toolbarOptions } from "./wysiwyEditorConfig";

const useStyles = makeStyles(() => ({
  wrapperClass: {
    padding: "1px",
    height: "250px",
  },
  editorClass: {
    padding: "16px",
    border: "1px solid #ccc",
  },
  toolbarClass: {
    border: "1px solid #ccc",
  },
}));

export const WysiwygEditor = (props) => {
  const { field, form, label } = props;
  const { name } = field;
  const { value } = field || props;

  const contentState = convertFromRaw(markdownToDraft(value));

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );

  const classes = useStyles();

  useEffect(() => {
    const rawData = convertToRaw(editorState.getCurrentContent());
    const markdownString = draftToMarkdown(rawData);

    form.setFieldValue(name, markdownString);
  }, [editorState]);

  return (
    <Column>
      {label && (
        <Text size="small" color="black200" style={{ marginTop: "12px" }}>
          {label}
        </Text>
      )}
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName={classes.wrapperClass}
        editorClassName={classes.editorClass}
        toolbarClassName={classes.toolbarClass}
        toolbar={toolbarOptions}
      />
    </Column>
  );
};
