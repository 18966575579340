import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const PaymentTypesList = (props) => {
  const { paymentTypes } = props;
  const history = useHistory();

  const filterFunction = (paymentType, filter) =>
    paymentType.id.toLowerCase().includes(filter) ||
    paymentType.type.toLowerCase().includes(filter);

  const handleRowClicked = useCallback((paymentType) => {
    history.push(`/paymentTypes/${paymentType.id}`);
  }, []);

  return (
    <DataTable
      title="Payment Types"
      data={paymentTypes}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
