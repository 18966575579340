import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Page } from "pages/Page";
import { CRUD_DATAPATH_TABLE_INFO, getItem, updateItem } from "api/crud";
import { EditTableItemForm } from "components/crud/EditTableItemForm";

export const EditTableItemPage = () => {
  const [item, setItem] = useState(null);
  const { dataPath, idValue } = useParams();
  const { pageTitle, idFields, customUpdate } =
    CRUD_DATAPATH_TABLE_INFO[dataPath];

  useEffect(() => {
    getItem(dataPath, idValue).then(setItem);
  }, [dataPath, idValue]);

  if (!item) {
    return <div>Loading Edit...</div>;
  }

  return (
    <Page>
      <EditTableItemForm
        item={item}
        idFields={idFields}
        title={pageTitle}
        onSubmit={customUpdate || ((values) => updateItem(dataPath, values))}
        dataPath={dataPath}
      />
    </Page>
  );
};
