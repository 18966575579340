import React from "react";

import { client } from "api";
import { Page } from "pages/Page";
import { AddInsuranceProviderForm } from "components/insuranceProviders/AddInsuranceProviderForm";

const addInsuranceProvider = async (values) => {
  try {
    const { data } = await client.post(`insuranceProviders/add`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error adding insurance provider: ${err.response.data}`);
  }
};

export const AddInsuranceProviderPage = () => {
  return (
    <Page>
      <AddInsuranceProviderForm onSubmit={addInsuranceProvider} />
    </Page>
  );
};
