import React, { useEffect } from "react";
import { Form, withFormik } from "formik";

import { Column } from "components/layout";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import {
  buildFacilityFields,
  useFacilityFields,
} from "hooks/facilityDetailsHooks";

export const EditFacilityBundleForm = withFormik({
  mapPropsToValues: ({ facilityBundle }) =>
    console.log("mapPropsToValues", facilityBundle) || {
      ...facilityBundle,
    },
  handleSubmit: async (values, { props, setSubmitting }) => {
    await props.onSubmit(values);
    setSubmitting(false);
  },
})((props) => {
  const { facilityBundle, values, handleChange, isSubmitting, setFieldValue } =
    props;

  const fields = useFacilityFields(facilityBundle, values);

  const disabledFields = ["facility_id", "name", "stripe_subscription_id"];
  if (!values.parent_id) {
    disabledFields.push("parent_pays_subscription");
    disabledFields.push("parent_pays_leads");
  }

  useEffect(() => {
    if (!values.parent_id) {
      setFieldValue("parent_pays_subscription", 0);
      setFieldValue("parent_pays_leads", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parent_id]);

  if (!fields) {
    return <div>Loading Clinic Form...</div>;
  }

  const {
    textFields,
    selectFields,
    multiSelectFields,
    textAreaFields,
    toggleFields,
  } = buildFacilityFields(fields, {
    handleSelectChange: handleChange,
    disabledFields,
  });

  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Edit Bundle
          </Text>
          <Column gap="12px">
            {textFields}
            {toggleFields}
            {selectFields}
            {multiSelectFields}
            {textAreaFields}
          </Column>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitting}
            disabled={isSubmitting}
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
