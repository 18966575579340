import React, { useMemo, forwardRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const sizes = {
  sizeSubscript: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
  },
  sizeCaption: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    fontWeight: 400,
  },
  sizeTiny: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '-0.1px',
  },
  sizeSmall: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '-0.1px',
  },
  sizeMedium: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
    letterSpacing: '-0.2px',
  },
  sizeLarge: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 400,
    letterSpacing: '-0.3px',
  },
  sizeCapsH2: {
    fontSize: '14px',
    lineHeight: '12px',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  sizeCapsH1: {
    fontSize: '16px',
    lineHeight: '14px',
    fontWeight: 700,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  sizeH8: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  sizeH7: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  sizeH6: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  sizeH5: {
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 600,
    letterSpacing: '-0.2px',
  },
  sizeH4: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 600,
    letterSpacing: '-0.4px',
  },
  sizeH3: {
    fontSize: '34px',
    lineHeight: '40px',
    fontWeight: 500,
    letterSpacing: '-0.4px',
  },
  sizeH2: {
    fontSize: '48px',
    lineHeight: '48px',
    fontWeight: 500,
    letterSpacing: '-1.2px',
  },
  sizeH1: {
    fontSize: '54px',
    lineHeight: '54px',
    fontWeight: 500,
    letterSpacing: '-0.8px',
  },
  sizeDisplay: {
    fontSize: '74px',
    lineHeight: '78px',
    fontWeight: 500,
    letterSpacing: '-1.6px',
  },
};

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: 'TTCommons, sans-serif',
    fontSmoothing: 'antialiased',
  },
  ...sizes,
  ...Object.keys(sizes).reduce((acc, size) => {
    acc[`mobile${size}`] = {
      [theme.breakpoints.down('xs')]: sizes[size],
    };
    return acc;
  }, {}),

  weight400: {
    fontWeight: 400,
  },
  weight500: {
    fontWeight: 500,
  },
  weight600: {
    fontWeight: 600,
  },
  weight700: {
    fontWeight: 700,
  },
  colorBlack0: {
    color: theme.palette.new.black[0],
  },
  colorBlack100: {
    color: theme.palette.new.black[100],
  },
  colorBlack200: {
    color: theme.palette.new.black[200],
  },
  colorSurface300: {
    color: theme.palette.new.surface[300],
  },
  colorPrimary: {
    color: theme.palette.new.primary[1000],
  },
  colorBlue: {
    color: theme.palette.new.blue[1000],
  },
  colorGreen: {
    color: theme.palette.new.green[1000],
  },
  colorRed: {
    color: theme.palette.new.red[1000],
  },
  colorPurple: {
    color: theme.palette.new.purple[1000],
  },
  colorYellow: {
    color: theme.palette.new.yellow[1000],
  },
  colorOrange: {
    color: theme.palette.new.orange[1000],
  },
  colorWhite: {
    color: theme.palette.new.surface.primary,
  },

  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
}));

export const Text = forwardRef((props, ref) => {
  const {
    component = 'span',
    size,
    mobileSize,
    weight,
    color,
    align,
    style,
    className,
    children,
    isCSR,
    ...rest
  } = props;

  const classes = useStyles();

  const sizeClass = useMemo(() => {
    if (!size) {
      return null;
    }
    switch (size) {
      case 'display':
        return classes.sizeDisplay;
      case 'h1':
        return classes.sizeH1;
      case 'h2':
        return classes.sizeH2;
      case 'h3':
        return classes.sizeH3;
      case 'h4':
        return classes.sizeH4;
      case 'h5':
        return classes.sizeH5;
      case 'h6':
        return classes.sizeH6;
      case 'h7':
        return classes.sizeH7;
      case 'h8':
        return classes.sizeH8;
      case 'capsH1':
        return classes.sizeCapsH1;
      case 'capsH2':
        return classes.sizeCapsH2;
      case 'large':
        return classes.sizeLarge;
      case 'medium':
        return classes.sizeMedium;
      case 'small':
        return classes.sizeSmall;
      case 'tiny':
        return classes.sizeTiny;
      case 'subscript':
        return classes.sizeSubscript;
      case 'caption':
        return classes.sizeCaption;

      default:
        console.error('invalid size passed to Text component:', size);
        return classes.sizeMedium;
    }
  }, [size, classes]);

  const mobileSizeClass = useMemo(() => {
    if (!mobileSize) {
      return null;
    }
    switch (mobileSize) {
      case 'display':
        return classes.mobilesizeDisplay;
      case 'h1':
        return classes.mobilesizeH1;
      case 'h2':
        return classes.mobilesizeH2;
      case 'h3':
        return classes.mobilesizeH3;
      case 'h4':
        return classes.mobilesizeH4;
      case 'h5':
        return classes.mobilesizeH5;
      case 'h6':
        return classes.mobilesizeH6;
      case 'h7':
        return classes.mobilesizeH7;
      case 'h8':
        return classes.mobilesizeH8;
      case 'capsH1':
        return classes.mobilesizeCapsH1;
      case 'capsH2':
        return classes.mobilesizeCapsH2;
      case 'large':
        return classes.mobilesizeLarge;
      case 'medium':
        return classes.mobilesizeMedium;
      case 'small':
        return classes.mobilesizeSmall;
      case 'tiny':
        return classes.mobilesizeTiny;
      case 'caption':
        return classes.mobilesizeCaption;
      default:
        console.error(
          'invalid mobile size passed to Text component:',
          mobileSize
        );
        return classes.mobilesizeMedium;
    }
  }, [size, classes]);

  const weightClass = useMemo(() => {
    if (!weight) {
      return null;
    }
    switch (weight) {
      case 700:
        return classes.weight700;
      case 600:
        return classes.weight600;
      case 500:
        return classes.weight500;
      case 400:
        return classes.weight400;
      default:
        console.error('invalid weight passed to Text component:', weight);
        return classes.weight400;
    }
  }, [weight, classes]);

  const colorClass = useMemo(() => {
    if (!color) {
      return null;
    }
    switch (color) {
      case 'black0':
      case 'black':
        return classes.colorBlack0;
      case 'black100':
        return classes.colorBlack100;
      case 'black200':
        return classes.colorBlack200;
      case 'surface300':
        return classes.colorSurface300;
      case 'green':
        return classes.colorGreen;
      case 'red':
        return classes.colorRed;
      case 'blue':
        return classes.colorBlue;
      case 'purple':
        return classes.colorPurple;
      case 'yellow':
        return classes.colorYellow;
      case 'orange':
        return classes.colorOrange;
      case 'primary':
        return classes.colorPrimary;
      case 'white':
        return classes.colorWhite;
      default:
        console.error('Invalid color passed to Text component:', color);
        return classes.colorBlack100;
    }
  }, [color, classes]);

  const alignClass = useMemo(() => {
    if (!align) {
      return null;
    }
    switch (align) {
      case 'left':
        return classes.alignLeft;
      case 'center':
        return classes.alignCenter;
      case 'right':
        return classes.alignRight;
      default:
        console.error('Invalid align passed to Text component:', align);
        return classes.alignLeft;
    }
  }, [align, classes]);

  const textClasses = classNames(
    classes.text,
    sizeClass,
    mobileSizeClass,
    weightClass,
    colorClass,
    alignClass,
    className
  );

  const Component = component || 'span';

  const optionalProps = {};
  if (typeof Component !== 'string') {
    optionalProps.isCSR = isCSR;
  }

  return (
    <Component
      className={textClasses}
      style={style}
      ref={ref}
      {...optionalProps}
      {...rest}
    >
      {children}
    </Component>
  );
});
