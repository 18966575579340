import React from "react";

import { Column } from "components/layout";
import { NavBar } from "components/nav/NavBar";

export const Page = (props) => {
  const { children } = props;
  return (
    <Column>
      <NavBar />
      <div style={{ padding: "20px" }}>{children}</div>
    </Column>
  );
};
