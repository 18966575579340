import React from "react";
import { Form, Field, withFormik } from "formik";
import { MenuItem } from "@material-ui/core";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { Select } from "components/ui/new/Select";
import { WysiwygEditor } from "components/misc/WysiwygEditor";

export const AddVerticalContentForm = withFormik({
  mapPropsToValues: () => ({
    facility_type: "",
    app_location: "",
    content_blurb: "",
  }),
  handleSubmit: (values, { props }) => {
    const formData = {
      facility_type: values?.facility_type?.trim() || null,
      app_location: values?.app_location?.trim() || "default",
      content_blurb: values?.content_blurb?.trim() || null,
    };

    props.onSubmit(formData);
  },
})((props) => {
  const { facilityTypes } = props;

  const facilityTypeOptions = facilityTypes.map((type) => (
    <MenuItem key={type.id} value={type.id}>
      {type.name}
    </MenuItem>
  ));

  return (
    <Column style={{ padding: "20px 120px" }}>
      <Form>
        <Column gap="12px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Vertical Content
          </Text>
          <Row>
            <Field
              component={Select}
              name="facility_type"
              label="facility_type"
              style={{ width: "400px" }}
            >
              {facilityTypeOptions}
            </Field>
          </Row>
          <Row>
            <Field
              component={TextField}
              name="app_location"
              label="app_location"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={WysiwygEditor}
              name="content_blurb"
              label="content_blurb"
            />
          </Row>

          <Row style={{ alignSelf: "flex-start", marginTop: "60px" }}>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
