import React from "react";

import { Page } from "pages/Page";
import { CRUD_DATAPATH_TABLE_INFO, insertItem } from "api/crud";
import { useColumnNames } from "hooks/useColumnNames";
import { useParams } from "react-router";
import { EditTableItemForm } from "components/crud/EditTableItemForm";

export const AddTableItemPage = () => {
  const { dataPath } = useParams();
  const tableColumns = useColumnNames(dataPath);
  const { pageTitle, idFields } = CRUD_DATAPATH_TABLE_INFO[dataPath];

  if (!tableColumns) {
    return <div>Loading Add...</div>;
  }

  const item = tableColumns.reduce((acc, column) => {
    acc[column] = "";
    return acc;
  }, {});

  return (
    <Page>
      <EditTableItemForm
        item={item}
        idFields={idFields}
        title={`Add ${pageTitle}`}
        onSubmit={(values) => insertItem(dataPath, values)}
        dataPath={dataPath}
        isEdit={false}
      />
    </Page>
  );
};
