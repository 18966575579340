import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column } from "components/layout";
import { Text } from "components/ui/new/Text";
import { TextField } from "components/ui/new/TextField";
import { PasswordField } from "components/ui/new//PasswordField";
import { Button } from "components/ui/new/Button";

export const LoginForm = withFormik({
  mapPropsToValues: () => ({ user_name: "", password: "" }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(() => {
  return (
    <Form>
      <Column align="center">
        <Column gap="8px" style={{ maxWidth: "300px" }}>
          <Text size="large" color="black100" style={{ alignSelf: "center" }}>
            Please Log In
          </Text>
          <Field component={TextField} name="user_name" label="Email" />
          <Field component={PasswordField} name="password" label="Password" />
          <Button color="primary" variant="contained" type="submit">
            Submit
          </Button>
        </Column>
      </Column>
    </Form>
  );
});
