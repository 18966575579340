import React from "react";

import { client } from "api";
import { Page } from "pages/Page";
import { AddPaymentTypeForm } from "components/paymentTypes/AddPaymentTypeForm";

const addPaymentType = async (values) => {
  try {
    const { data } = await client.post(`paymentTypes/add`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error adding payment type: ${err.response.data}`);
  }
};

export const AddPaymentTypePage = () => {
  return (
    <Page>
      <AddPaymentTypeForm onSubmit={addPaymentType} />
    </Page>
  );
};
