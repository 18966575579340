import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";

export const EditBundleForm = withFormik({
  mapPropsToValues: ({ bundle }) => ({
    ...bundle,
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({ ...values, name: values.name.trim() || null });
  },
})(() => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Edit Bundle
          </Text>
          <Row wrap gap="12px">
            <Field component={TextField} name="id" label="ID" disabled />
            <Field component={TextField} name="name" label="Name" />
            <Field component={TextField} name="is_free" label="Is Free" />
            <Field component={TextField} name="highlight_level" label="Highlight Level" />
            <Field component={TextField} name="boost_multiplier" label="Boost Multiplier" />
          </Row>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
