import React from "react";
import { Form, Field, withFormik } from "formik";
import moment from "moment";

import { Column, Row } from "components/layout";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { TextField } from "components/ui/new/TextField";
import { DatePickerField } from "components/ui/DatePickerField";

export const EditSponsoredListingForm = withFormik({
  mapPropsToValues: ({ sponsoredListing }) => ({
    id: sponsoredListing.id,
    facility_id: sponsoredListing.facility_id,
    start: moment(sponsoredListing.start).format("YYYY-MM-DD"),
    end: moment(sponsoredListing.end).format("YYYY-MM-DD"),
    impressions_issued: sponsoredListing.impressions_issued,
    impressions_remaining: sponsoredListing.impressions_remaining,
    impressions_added: 0,
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(() => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Edit Extra Sponsored Listing Impressions
          </Text>
          <Field component={TextField} name="id" label="ID" disabled />
          <Field component={TextField} name="facility_id" label="Facility ID" disabled />
          <Field component={TextField} name="impressions_issued" label="Impressions Issued" disabled />
          <Field component={TextField} name="impressions_remaining" label="Impressions Remaining" disabled />
          <Field component={TextField} name="impressions_added" label="Add Impressions" />
          <Row gap="36px">
            <Field
              component={DatePickerField}
              name="start"
              label="Start Date"
            />
            <Field component={DatePickerField} name="end" label="End Date" />
          </Row>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
