import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";
import { CRUD_DATAPATH_TABLE_INFO, getItemKeyPath } from "api/crud";

export const DataListComponent = ({
  data,
  columnNames,
  dataPath,
  title,
  idFields,
  handleRowDelete,
}) => {
  const history = useHistory();

  const filterFunction = useCallback(
    (currentItem, filterQuery) => {
      const res = columnNames.reduce((acc, currentField) => {
        return (
          acc ||
          (currentItem[currentField] &&
            currentItem[currentField]
              .toString()
              .toLowerCase()
              .includes(filterQuery.toLowerCase()))
        );
      }, false);
      return res;
    },
    [columnNames]
  );

  const handleRowClicked = useCallback(
    (item) => {
      history.push(
        `/table/${dataPath}/${encodeURIComponent(
          getItemKeyPath(idFields, item)
        )}`
      );
    },
    [dataPath, history, idFields]
  );

  const customizeColumns = useCallback(
    (columns) => {
      const customize = CRUD_DATAPATH_TABLE_INFO[dataPath].customizeColumns;
      return customize ? customize(columns) : columns;
    },
    [dataPath]
  );

  return (
    <DataTable
      title={title}
      data={data}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
      onRowDeleteClicked={handleRowDelete}
      columnNames={customizeColumns(columnNames)}
    />
  );
};
