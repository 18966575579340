import React from "react";

import { client } from "api";
import { Page } from "pages/Page";
import { AddSponsoredListingForm } from "components/facilities/AddSponsoredListingForm";

const addSponsoredListing = async (values) => {
  try {
    const { data } = await client.post(`clinics/sponsoredListings/add`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error adding sponsored listing: ${err.response.data}`);
  }
};

export const AddSponsoredListingPage = () => {
  return (
    <Page>
      <AddSponsoredListingForm onSubmit={addSponsoredListing} />
    </Page>
  );
};
