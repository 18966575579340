import { client } from "./client";

export const updateReview = async (values) => {
  try {
    const { data } = await client.post(`reviews/${values.id}/update`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error updating review: ${err.response.data}`);
    return null;
  }
};
