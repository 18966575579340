import React from 'react';

const rvhRegex = /(\d+(\.\d*)?)rvh(?!\w)/g;

const divHeight = '100rvh';

export class Div100vh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
    };
  }

  componentDidMount() {
    this.updateStyle();
    window.addEventListener('resize', this.updateStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateStyle);
  }

  updateStyle = () => {
    const { style: styleProps } = this.props;
    const windowHeight =
      (typeof document !== 'undefined' &&
        document.documentElement &&
        document.documentElement.clientHeight) ||
      window.innerHeight;
    const windowWidth =
      (typeof document !== 'undefined' &&
        document.documentElement &&
        document.documentElement.clientWidth) ||
      window.innerWidth;
    if (
      (windowWidth < 786 && windowWidth > windowHeight) ||
      windowHeight < 650 ||
      (windowHeight > 850 && windowWidth < 1601) ||
      (windowWidth > 786 && windowHeight < 786)
    ) {
      this.setState({ style: { height: 'auto' } });
      return;
    }
    const height = divHeight.replace(
      rvhRegex,
      (_, rvh) =>
        `${(windowHeight * parseFloat(rvh)) / 100 -
          (windowWidth > 786 ? 110 : 80)}px`
    );
    this.setState({
      style: {
        height,
        maxHeight: windowWidth > 786 ? height : 'auto',
        ...styleProps,
      },
    });
  };

  render() {
    const isIE = typeof document !== 'undefined' && !!document.documentMode;
    const { as: Element = 'div', ...props } = this.props;
    if (isIE) return <Element {...props} />;
    const { style } = this.state;
    return <Element {...props} style={style} />;
  }
}
