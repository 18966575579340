import React from 'react';
import {
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const generateClassName = createGenerateClassName({
  productionPrefix: 'mm',
});

// TODO: Add more base theming to match Zeplin
const theme = createTheme({
  typography: {
    fontFamily: ['TTCommons', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: '900',
    },
  },
  palette: {
    common: {
      black: '#101426',
      white: '#ffffff',
    },
    primary: {
      main: '#2643ea',
    },
    secondary: {
      main: '#1b1871',
    },
    tertiary: {
      main: '#999',
      light: 'rgb(173, 173, 173)',
      dark: 'rgb(107, 107, 107)',
    },
    error: {
      main: '#e40f00',
    },
    success: {
      main: '#72ca89',
      dark: '#008000',
    },
    warning: {
      main: '#ee851f',
    },
    info: {
      main: '#8f9bb3',
    },
    grey: {
      100: '#f7f9fc',
      200: '#e4e9f2',
      300: '#c5cee0',
      400: '#8f9bb3',
      500: '#2e3a59',
      alternate: '#7b7b7b',
    },
    turquoise: {
      main: '#4cc0b1',
      medium: '#c1ded4',
      dark: '#008f82',
      light: '#82f3e3',
    },
    orange: {
      main: '#ea5026',
      dark: '#a3381a',
      light: '#ee7351',
    },
    text: {
      primary: '#101426',
    },
    new: {
      surface: {
        primary: 'white',
        secondary: '#f7f9fB',
        secondaryActive: '#e8ebed',
        300: '#B3B7C2',
      },
      black: {
        0: '#000',
        100: '#1E2432',
        200: '#687086',
        600: 'rgba(0, 0, 0, .15)',
        800: 'rgba(0, 0, 0, .20)',
        1000: '#000',
      },
      primary: {
        200: 'rgba(26, 93, 200, 0.2)',
        1000: '#1A5DC8',
      },
      blue: {
        50: 'rgba(67, 151, 248, 0.05)',
        100: 'rgba(67, 151, 248, 0.1)',
        200: 'rgba(67, 151, 248, 0.2)',
        600: 'rgba(67, 151, 248, 0.6)',
        1000: '#4397F8',
        dark: '#0870e7',
      },
      green: {
        200: 'rgba(103, 198, 161, 0.1)',
        600: 'rgba(103, 198, 161, 0.6)',
        1000: '#67C6A1',
        dark: '#3ea37c',
      },
      red: {
        200: 'rgba(239, 140, 107, 0.1)',
        600: 'rgba(239, 140, 107, 0.6)',
        1000: '#EF8C6B',
        dark: '#e75727',
      },
      yellow: {
        200: 'rgba(249, 220, 108, 0.2)',
        600: 'rgba(249, 220, 108, 0.6)',
        1000: '#F9DC6C',
        dark: '#f6cc23',
      },
      purple: {
        200: 'rgba(204, 167, 228, 0.1)',
        600: 'rgba(204, 167, 228, 0.6)',
        1000: '#CCA8E4',
        dark: '#ab6fd3',
      },
      orange: {
        200: 'rgba(234, 80, 38, 0.2)',
        600: 'rgba(234, 80, 38, 0.6)',
        1000: '#ea5026',
        dark: '#a3381a',
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: '500',
        '&.Mui-contained': {
          boxShadow: 'unset',
        },
        '&.Mui-outlined': {
          boxShadow: 'unset',
        },
        '&.Mui-text': {
          boxShadow: 'unset',
        },
        borderRadius: '8px',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#2e3a59',
          },
        },
        '& .MuiOutlinedInput-inputMarginDense': {
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
        fontFamily: 'TTCommons, sans-serif',
        lineHeight: '20px',
        letterSpacing: '-0.1px',
        color: 'white',
        backgroundColor: '#1E2432',
        padding: '12px 16px',
        borderRadius: '8px',
        fontWeight: '300',
        '& p': {
          margin: '0',
        },
        '& p + p': {
          marginTop: '8px',
        },
        '& strong': {
          fontWeight: '600',
        },
      },
      arrow: {
        color: '#1E2432',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2560,
    },
  },
  spacing: 4,
});

export const Theme = ({ children }) => {
  const GlobalCss = withStyles({
    '@global': {
      html: {
        scrollBehavior: 'smooth',
      },
      '.Mui-required': {
        color: '#e40f00',
        marginLeft: '14px',
      },
    },
  })(() => null);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};
