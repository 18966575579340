import React from "react";

import { Row } from "components/layout";

const OLD_ADMIN_PORTAL_ROOT = process.env.REACT_APP_API_ROOT;

const getHref = (path) => new URL(path, OLD_ADMIN_PORTAL_ROOT).toString();

export const NavBar = () => (
  <nav
    className="navbar navbar-expand navbar-light bg-faded"
    style={{ backgroundColor: "#fafafa", border: "1px solid #e4e4e4" }}
  >
    <Row align="center">
      <img
        src="./assets/logo.png"
        alt="medimap logo"
        style={{ width: "32px", height: "32px" }}
      />
      <a className="navbar-brand" href={OLD_ADMIN_PORTAL_ROOT}>
        Medimap
      </a>
      <div className="navbar-collapse" id="navbarNav">
        <script language="javascript"></script>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link" href="/clinics/create">
              Create Clinic
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/users/invite")}>
              Invite User
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/alerts")}>
              View Alerts
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/vc/sessions")}>
              VC Session Log
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/vc/active")}>
              VC Active Clinics
            </a>
          </li>
          <li className="nav-item">
            <a
              id="logout"
              className="nav-link"
              onClick={() => {
                localStorage.removeItem("authToken");
              }}
              href={getHref("/login")}
            >
              Logout
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href={getHref("/wait")}>
              Wait Time Analytics
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/clinics")}>
              Clinics
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/pharmacies")}>
              Pharmacies
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/checkins")}>
              Pending Checkins
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/checkins/logs")}>
              Checkin Logs
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={getHref("/users")}>
              Users
            </a>
          </li>
        </ul>
      </div>
    </Row>
  </nav>
);
