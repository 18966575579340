import React, { useEffect, useState } from "react";

import { client } from "api";
import { getFacilityTypes } from "api/facilityTypes";
import { Page } from "pages/Page";
import { AddSymptomForm } from "components/symptoms/AddSymptomForm";

const addSymptom = async (values) => {
  try {
    const { data } = await client.post(`symptoms/add`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error adding symptom: ${err.response.data}`);
  }
};

export const AddSymptomPage = () => {
  const [facilityTypes, setFacilityTypes] = useState(null);

  useEffect(() => {
    getFacilityTypes().then(setFacilityTypes);
  }, []);

  if (!facilityTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <AddSymptomForm facilityTypes={facilityTypes} onSubmit={addSymptom} />
    </Page>
  );
};
