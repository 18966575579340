import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ align }) => align,
    justifyContent: ({ justify }) => justify,

    '&>:not(:last-child)': {
      marginBottom: ({ gap }) => gap,
    },
  },
}));

export const Column = React.memo(
  React.forwardRef((props, ref) => {
    const { gap, style, justify, align, className, ...rest } = props;
    const classes = useStyles({ gap, justify, align });
    return (
      <div
        className={classNames(classes.column, className)}
        style={style}
        ref={ref}
        {...rest}
      />
    );
  })
);
