import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getBundle, updateBundle } from "api/bundles";
import { Page } from "pages/Page";
import { EditBundleForm } from "components/bundles/EditBundleForm";

export const EditBundlePage = () => {
  const [bundle, setBundle] = useState(null);
  const { bundleId } = useParams();

  useEffect(() => {
    getBundle(bundleId).then(setBundle);
  }, [bundleId]);

  if (!bundle) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditBundleForm
        bundle={bundle}
        onSubmit={updateBundle}
      />
    </Page>
  );
};
