import { client } from "./client";

export const getInsuranceProviders = async () => {
  const { data } = await client.get("insuranceProviders");
  return data.insuranceProviders;
};

export const getInsuranceProvider = async (id) => {
  const { data } = await client.get(`insuranceProviders/${id}`);
  return data.insuranceProvider;
};

export const updateInsuranceProvider = async (values) => {
  try {
    const { data } = await client.post(
      `insuranceProviders/${values.id}/update`,
      values
    );
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing insurance provider: ${err.response.data}`);
    return null;
  }
};
