import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { MultiSelect } from "components/ui/new/MultiSelect";
import { WysiwygEditor } from "components/misc/WysiwygEditor";

export const AddSymptomForm = withFormik({
  mapPropsToValues: () => ({
    id: "",
    name: "",
    path: "",
    icon_id: "",
    meta_description: "",
    content_blurb: "",
    facilityTypes: [],
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({
      ...values,
      meta_description: values?.meta_description?.trim() || null,
      content_blurb: values?.content_blurb?.trim() || null,
    })
  },
})((props) => {
  const { facilityTypes } = props;
  const facilityTypeOptions = facilityTypes.map((type) => ({
    id: type.id,
    label: type.name,
  }));
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Symptom
          </Text>
          <Row wrap gap="12px">
            <Field component={TextField} name="id" label="ID" />
            <Field component={TextField} name="name" label="Name" />
            <Field component={TextField} name="path" label="Path" />
            <Field component={TextField} name="icon_id" label="Icon ID" />
          </Row>
          <Field
            component={TextField}
            name="meta_description"
            label="Meta Description"
          />
          <Field
            component={MultiSelect}
            options={facilityTypeOptions}
            name="facilityTypes"
            label="Facility Types"
          />
          <Field
            component={WysiwygEditor}
            name="content_blurb"
            label="content_blurb"
          />
          <Row style={{ alignSelf: "flex-end", marginTop: "72px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
            >
              Save
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
