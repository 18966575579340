import { Field } from "formik";
import { MenuItem } from "@material-ui/core";

import { TextField } from "components/ui/new/TextField";
import { Select } from "components/ui/new/Select";

import { client } from "./client";
import { updateReview } from "./facilityReviews";

const specifiedOrderComparator = (specifiedOrder) => (a, b) => {
  const indexA = specifiedOrder.indexOf(a);
  const indexB = specifiedOrder.indexOf(b);
  if (indexA === -1 && indexB === -1) {
    return a.localeCompare(b);
  }
  if (indexA === -1) {
    return 1;
  }
  if (indexB === -1) {
    return -1;
  }
  return specifiedOrder.indexOf(a) - specifiedOrder.indexOf(b);
};

export const CRUD_DATAPATH_TABLE_INFO = {
  customSeoTags: {
    tableName: "webpage_custom_tags",
    idFields: [{ name: "page_path", autogenerated: false }],
    pageTitle: "Custom Seo Tags",
  },
  facilityExternalIds: {
    tableName: "facility_external_ids",
    idFields: [
      { name: "facility_id", autogenerated: false },
      { name: "external_id", autogenerated: false },
      { name: "provider", autogenerated: false },
    ],
    pageTitle: "Facility External Ids",
  },
  symptoms: {
    tableName: "symptoms",
    idFields: [{ name: "id", autogenerated: true }],
    pageTitle: "Symptoms",
    customizeColumns: () => ["id", "name", "path", "icon_id", "popular"],
  },
  calendars: {
    tableName: "staff_calendars",
    idFields: [{ name: "id", autogenerated: true }],
    pageTitle: "Staff Calendars",
    // sort columns such that 'invalid' is first
    customizeColumns: (columns) => {
      const invalidColumn = columns.find((column) => column === "invalid");
      const otherColumns = columns.filter((column) => column !== "invalid");
      return [invalidColumn, ...otherColumns];
    },
  },
  facilityReviews: {
    tableName: "facility_reviews",
    pageTitle: "Facility Reviews",
    idFields: [{ name: "id", autogenerated: true }],
    readOnly: ["created_at"],
    customFields: {
      review_text: {
        component: (
          <Field
            component={TextField}
            key="review_text"
            name="review_text"
            label="Review Text"
            multiline
            minRows={4}
            maxRows={6}
          />
        ),
      },
      status: {
        component: (
          <Field key="status" component={Select} name="status" label="Status">
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
          </Field>
        ),
      },
      source: {
        component: (
          <Field key="source" component={Select} name="source" label="Source">
            <MenuItem value="Medimap">Medimap</MenuItem>
            <MenuItem value="Google">Google</MenuItem>
            <MenuItem value="RateMD">RateMD</MenuItem>
          </Field>
        ),
      },
    },
    customUpdate: updateReview,
    customizeColumns: (columns) => {
      const order = ["id", "facility_id", "status", "rating"];
      return columns.sort(specifiedOrderComparator(order));
    },
  },
};

export const getItemKeyPath = (idFields, item) => {
  return idFields.map(({ name }) => item[name]).join(",");
};

export const getTableColumns = async (dataPath) => {
  const { tableName } = CRUD_DATAPATH_TABLE_INFO[dataPath];
  const { data } = await client.get(`tableColumns/${tableName}`);
  return data.columns;
};

export const getAllItems = async (dataPath) => {
  const { tableName } = CRUD_DATAPATH_TABLE_INFO[dataPath];
  const { data } = await client.get(`crud/${tableName}`);
  return data.tableItems;
};

export const getItem = async (dataPath, idValue) => {
  const { tableName, idFields } = CRUD_DATAPATH_TABLE_INFO[dataPath];
  const { data } = await client.get(
    `crud/${tableName}/${idValue}?idFields=${idFields
      .map((f) => f.name)
      .join(",")}`
  );
  return data.item;
};

export const updateItem = async (dataPath, values) => {
  try {
    const { tableName, idFields } = CRUD_DATAPATH_TABLE_INFO[dataPath];
    const idPath = getItemKeyPath(idFields, values);
    await client.post(
      `crud/${tableName}/${encodeURIComponent(
        idPath
      )}/update?idFields=${idFields.map((f) => f.name).join(",")}`,
      values
    );
    window.alert("success");
  } catch (e) {
    console.error(e);
    alert("failed to update!");
  }
};

export const insertItem = async (dataPath, values) => {
  try {
    const { tableName, readOnly = [] } = CRUD_DATAPATH_TABLE_INFO[dataPath];
    readOnly.forEach((field) => delete values[field]);
    const { data } = await client.post(`crud/${tableName}/add`, values);
    console.log(data);
    window.alert(`success inserting item with id ${data.insertId}`);
  } catch (e) {
    console.error(e);
    alert("failed to insert!");
  }
};

export const deleteItem = async (dataPath, idValue) => {
  try {
    const { tableName, idFields } = CRUD_DATAPATH_TABLE_INFO[dataPath];
    await client.delete(
      `crud/${tableName}/${idValue}?idFields=${idFields
        .map((f) => f.name)
        .join(",")}`
    );
    window.alert(`item deleted`);
  } catch (e) {
    console.error(e);
    alert("failed to delete!");
  }
};
