import { getTableColumns } from "api/crud";
import { useEffect, useState } from "react";

export const useColumnNames = (dataPath) => {
  const [columnNames, setColumnNames] = useState();
  useEffect(() => {
    getTableColumns(dataPath).then(setColumnNames);
  }, [dataPath]);
  return columnNames;
};
