import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const VerticalContentList = (props) => {
  const { allVerticalContent } = props;
  const history = useHistory();

  const filterFunction = (verticalContent, filter) => {
    const filterLoweCase = filter?.toLowerCase();

    return (
      verticalContent.id.toString().toLowerCase().includes(filter) ||
      verticalContent.facility_type.toLowerCase().includes(filterLoweCase) ||
      verticalContent.name.toLowerCase().includes(filterLoweCase) ||
      verticalContent.app_location.toLowerCase().includes(filterLoweCase) ||
      verticalContent.content_blurb.toLowerCase().includes(filterLoweCase)
    );
  };

  const handleRowClicked = useCallback(
    (verticalContent) => {
      history.push(`/verticalContent/${verticalContent.id}`);
    },
    [history]
  );

  const handleRowActionButtonClicked = useCallback(
    (verticalContent) => {
      history.push(`/verticalContent/duplicate/${verticalContent.id}`);
    },
    [history]
  );

  return (
    <DataTable
      title="Vertical Content"
      data={allVerticalContent}
      cellWidth="300px"
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
      onRowActionButtonClicked={handleRowActionButtonClicked}
    />
  );
};
