import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { WysiwygEditor } from "components/misc/WysiwygEditor";

export const EditFaqForm = withFormik({
  mapPropsToValues: ({ faqItem }) => ({
    ...faqItem,
  }),
  handleSubmit: (values, { props }) => {
    const { isDuplicate } = props;
    const formData = {
      ...values,
      index: values?.index?.toString()?.trim() || null,
      app_location: values?.app_location?.trim() || "default",
      question: values?.question?.trim() || null,
      answer_body: values?.answer_body?.trim() || null,
    };
    if (isDuplicate) {
      delete formData.id;
    }
    props.onSubmit({ values: formData, isDuplicate });
  },
})((props) => {
  const { faqItem, isDuplicate } = props;
  return (
    <Column style={{ padding: "20px 120px" }}>
      <Form>
        <Column gap="12px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            {isDuplicate ? "Duplicate" : "Edit"} FAQ {faqItem.id}
          </Text>
          <Row wrap gap="12px">
            <Field component={TextField} name="id" label="id" disabled />
            <Field
              component={TextField}
              name="facility_type"
              label="facility_type"
              disabled
            />
            <Field component={TextField} name="index" label="index" />
          </Row>
          <Row>
            <Field
              component={TextField}
              name="app_location"
              label="app_location"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={TextField}
              name="question"
              label="question"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={WysiwygEditor}
              name="answer_body"
              label="answer_body"
            />
          </Row>
          <Row style={{ alignSelf: "flex-start", marginTop: "60px" }}>
            <Button type="submit" variant="contained" color="primary">
              {isDuplicate ? "Duplicate" : "Update"}
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
