import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { PromotionList } from "components/promotions/PromotionList";
import { Button } from "components/ui/new/Button";

const getPromotions = async () => {
  const { data } = await client.get("promotions");
  console.log("getPromotions", data);
  return data.promotions;
};

export const PromotionsPage = () => {
  const [promotions, setPromotions] = useState(null);

  useEffect(() => {
    getPromotions().then(setPromotions);
  }, []);

  if (!promotions) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <PromotionList promotions={promotions} />
        <Button
          component={Link}
          to="/promotions/add"
          variant="contained"
          color="primary"
        >
          Add Promotion
        </Button>
      </Column>
    </Page>
  );
};
