import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  getInsuranceProvider,
  updateInsuranceProvider,
} from "api/insuranceProvders";
import { Page } from "pages/Page";
import { EditInsuranceProviderForm } from "components/insuranceProviders/EditInsuranceProviderForm";

export const EditInsuranceProviderPage = () => {
  const [insuranceProvider, setInsuranceProvider] = useState(null);
  const { insuranceProviderId } = useParams();

  useEffect(() => {
    getInsuranceProvider(insuranceProviderId).then(setInsuranceProvider);
  }, [insuranceProviderId]);

  if (!insuranceProvider) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditInsuranceProviderForm
        insuranceProvider={insuranceProvider}
        onSubmit={updateInsuranceProvider}
      />
    </Page>
  );
};
