import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const FacilityBundlesList = (props) => {
  const { facilityBundles } = props;
  const history = useHistory();

  const filterFunction = (bundle, filter) =>
    bundle.facility_id.toString().includes(filter) ||
    bundle.bundle_id?.toLowerCase()?.includes(filter.toLowerCase()) ||
    bundle.variant_id?.toLowerCase()?.includes(filter.toLowerCase()) ||
    bundle.parent_id?.toString()?.includes(filter);

  const handleRowClicked = useCallback(
    (bundle) => {
      history.push(`/facilityBundles/${bundle.facility_id}`);
    },
    [history]
  );

  return (
    <DataTable
      title="Facility Bundles"
      data={facilityBundles}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
