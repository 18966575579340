import { client } from "./client";

export const getClinic = async (id) => {
  const { data } = await client.get(`clinics/${id}`);
  return data.clinic;
};

export const updateClinic = async (values) => {
  try {
    const { data } = await client.post(`clinics/${values.id}/update`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing clinic: ${err.response.data}`);
  }
};

export const createClinic = async (values) => {
  try {
    const {
      data: { insertId },
    } = await client.post(`clinics/create`, values);
    window.alert(`successfully inserted clinic with id: ${insertId}`);
    return insertId;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error creating clinic: ${err.response.data}`);
  }
};

export const getFacilityAudit = async (id) => {
  try {
    const { data } = await client.get(`/clinics/facilityAudit/${id}`);
    const mappedData = data.facilityAudit.map((record) => {
      const { id, ...mappedRecord } = record;
      return mappedRecord;
    });
    return mappedData;
  } catch (err) {
    console.error(err);
    window.alert(`Error getting facility audit: ${err.response.data}`);
  }
};
