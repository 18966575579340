import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getInsuranceProviders } from "api/insuranceProvders";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { InsuranceProvidersList } from "components/insuranceProviders/InsuranceProvidersList";
import { Button } from "components/ui/new/Button";

export const InsuranceProvidersPage = () => {
  const [insuranceProviders, setInsuranceProviders] = useState(null);

  useEffect(() => {
    getInsuranceProviders().then(setInsuranceProviders);
  }, []);

  if (!insuranceProviders) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <InsuranceProvidersList insuranceProviders={insuranceProviders} />
        <Button
          component={Link}
          to="/insuranceProviders/add"
          variant="contained"
          color="primary"
        >
          Add Insurance Provider
        </Button>
      </Column>
    </Page>
  );
};
