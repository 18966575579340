import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { getFacilityTypes } from "api/facilityTypes";
import { Page } from "pages/Page";
import { EditServiceForm } from "components/services/EditServiceForm";

const getService = async (id) => {
  const { data } = await client.get(`services/${id}`);
  return data.service;
};

const updateService = async (values) => {
  try {
    const { data } = await client.post(`services/${values.id}/update`, values);
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing service: ${err.response.data}`);
  }
};

export const EditServicePage = () => {
  const [service, setService] = useState(null);
  const [facilityTypes, setFacilityTypes] = useState(null);
  const { serviceId } = useParams();

  useEffect(() => {
    getService(serviceId).then(setService);
  }, [serviceId]);
  useEffect(() => {
    getFacilityTypes().then(setFacilityTypes);
  }, []);

  if (!service || !facilityTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditServiceForm
        service={service}
        facilityTypes={facilityTypes}
        onSubmit={updateService}
      />
    </Page>
  );
};
