import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Page } from "pages/Page";
import { EditFacilityBundleForm } from "components/facilityBundles/EditFacilityBundleForm";
import {
  getFacilityBundleInfo,
  updateFacilityBundle,
} from "api/facilityBundles";

export const EditFacilityBundlePage = () => {
  const { facilityId } = useParams();
  const [facilityBundle, setFacilityBundle] = useState(null);

  useEffect(() => {
    getFacilityBundleInfo(facilityId).then(setFacilityBundle);
  }, [facilityId]);

  if (!facilityBundle) {
    return <div>Loading...</div>;
  }

  console.log("facilityBundle:", facilityBundle);

  return (
    <Page>
      <EditFacilityBundleForm
        facilityBundle={facilityBundle}
        onSubmit={updateFacilityBundle}
      />
    </Page>
  );
};
