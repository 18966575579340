import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const FacilityFeatureOverrideList = (props) => {
  const { facilityFeatureOverrides } = props;
  const history = useHistory();

  const filterFunction = (facilityFeature, filter) => {
    const filterLoweCase = filter?.toLowerCase();

    return (
      facilityFeature.facility_id.toString().includes(filter) ||
      facilityFeature.feature_id.toLowerCase().includes(filterLoweCase) ||
      facilityFeature?.facility_name?.toLowerCase().includes(filterLoweCase) ||
      facilityFeature?.feature_name?.toLowerCase().includes(filterLoweCase)
    );
  };

  const handleRowClicked = useCallback(
    (facilityFeature) => {
      history.push(
        `/facilityFeatureOverrides/${facilityFeature.facility_id}/${facilityFeature.feature_id}`
      );
    },
    [history]
  );

  return (
    <DataTable
      title="Facility Feature Overrides"
      data={facilityFeatureOverrides}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
