import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditVerticalContentForm } from "components/verticalContent/EditVerticalContentForm";

const getVerticalContent = async (verticalContentId) => {
  const { data } = await client.get(`verticalContent/${verticalContentId}`);
  return data.verticalContent;
};

const updateVerticalContent = async ({ values, isDuplicate }) => {
  if (!values.content_blurb) {
    window.alert(
      `Error ${
        isDuplicate ? "duplicating" : "editing"
      } Vertical Content: content_blurb is a required field`
    );
    return;
  }
  try {
    if (isDuplicate) {
      await client.post(`verticalContent/add`, values);
    } else {
      await client.post(`verticalContent/${values.id}/update`, values);
    }
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    const errText = err.response.data.includes(
      "vertical_content-facility_type-app_location"
    )
      ? `Duplicate entry already exists for facility_type = ${values.facility_type} and app_location = ${values.app_location}`
      : err.response.data;

    window.alert(
      `Error ${
        isDuplicate ? "duplicating" : "editing"
      } Vertical Content: ${errText}`
    );
  }
};

export const EditVerticalContentPage = () => {
  const [verticalContent, setVerticalContent] = useState(null);
  const { verticalContentId, duplicate } = useParams();

  const isDuplicate = duplicate === "duplicate";

  useEffect(() => {
    getVerticalContent(verticalContentId).then(setVerticalContent);
  }, [verticalContentId]);

  if (!verticalContent) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditVerticalContentForm
        verticalContent={verticalContent}
        isDuplicate={isDuplicate}
        onSubmit={updateVerticalContent}
      />
    </Page>
  );
};
