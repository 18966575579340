import React, { useCallback } from 'react';
import { CaretDown } from 'phosphor-react';
import useTheme from '@material-ui/core/styles/useTheme';
import { TextField } from './TextField';

export const Select = props => {
  const { field, form, label, onChange, children, ...rest } = props;
  const { value } = field || props;
  const { name } = field || {};
  const { errors, touched } = form || {
    errors: {},
    touched: {},
  };
  const error = errors[name];
  const fieldTouched = touched[name];
  const theme = useTheme();

  const handleChange = useCallback(e => {
    const newValue = typeof e === 'object' ? e.target.value : e;
    if (onChange) {
      onChange(newValue);
    }
    if (form) {
      form.setFieldValue(name, newValue);
    }
  }, []);

  return (
    <TextField
      select
      field={field}
      form={form}
      label={label}
      value={field?.value || value}
      onChange={handleChange}
      helperText={fieldTouched && error}
      error={!!(fieldTouched && error)}
      endAdornment={
        <CaretDown
          weight="fill"
          size={20}
          color={theme.palette.new.black[200]}
        />
      }
      {...rest}
    >
      {children}
    </TextField>
  );
};
