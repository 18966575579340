import React, { useCallback, useMemo, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import MUITextField from '@material-ui/core/TextField';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WarningCircle } from 'phosphor-react';
import classNames from 'classnames';

import { Row } from 'components/layout';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'TTCommons, sans-serif',
    '&>.MuiInputBase-root': {
      borderRadius: '8px',
      '&>.MuiSelect-icon': {
        display: 'none',
      },
    },
    '&>.MuiInputBase-root:not(.MuiOutlinedInput-root)': {
      backgroundColor: theme.palette.new.surface.secondary,
      '&>.MuiInputBase-input': {
        color: theme.palette.new.black[100],
        borderRadius: 'inherit',
      },
      '&.Mui-error': {
        border: `1px solid ${theme.palette.new.red[1000]}`,
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.new.blue[1000]}`,
      },
    },
    '&>.MuiInputBase-root.MuiOutlinedInput-root': {
      '& .MuiInputBase-input::placeholder': {
        opacity: '1',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.new.red[1000],
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.new.blue[1000],
        borderWidth: '1px',
      },
    },
    '& .MuiInputLabel-outlined.MuiFormLabel-filled': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      fontFamily: 'TTCommons, sans-serif !important',
    },
    '&>.MuiFormLabel-root': {
      fontFamily: 'TTCommons, sans-serif !important',
      color: theme.palette.new.black[200],
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'TTCommons, sans-serif !important',
    },
    '&>.MuiFormHelperText-root.Mui-error': {
      color: theme.palette.new.red[1000],
    },
    '& .MuiSelect-select': {
      fontSize: '20px',
    },
    '& .MuiSelect-selectMenu': {
      minHeight: 'unset',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '&.MuiFilledInput-adornedEnd': {
      paddingRight: '0',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '20px',
      top: '-2px',
    },
  },
  rounded: {
    '&$root>.MuiInputBase-root': {
      borderRadius: '999px',
    },
  },
  withIcon: {
    '&$root .MuiInputLabel-root': {
      paddingLeft: '40px',
      fontSize: '20px',
      color: theme.palette.new.black[200],
    },
  },
  noLabel: {
    '&$root .MuiInputBase-input': {
      padding: '16px 12px',
      fontSize: '20px',
      '&::placeholder': {
        color: theme.palette.new.black[200],
      },
    },
  },
  menuPaper: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 4px 16px 0px rgb(0, 0, 0, 0.15)',
    borderRadius: '16px',
  },
  menuList: {
    fontFamily: 'TTCommons, sans-serif',
    '&>li': {
      fontSize: '18px',
      fontWeight: 400,
    },
  },
  selectRoot: {
    '&$root .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
  },
  endAdornmentForSelect: {
    position: 'absolute',
    right: 0,
    paddingRight: '12px',
  },
}));

export const TextField = props => {
  const {
    field,
    form,
    label,
    onChange,
    icon,
    rounded,
    select,
    onBlur,
    onFocus,
    endAdornment: endAdornmentExternal,
    variant = 'filled',
    ...rest
  } = props;
  const { value } = field || props;
  const { name } = field || {};
  const { errors, touched } = form || {
    errors: {},
    touched: {},
  };
  const error = errors[name];
  const fieldTouched = touched[name];

  const classes = useStyles();
  const theme = useTheme();

  const [isFocused, setIsFocused] = useState(false);
  const shrink = useMemo(
    () => !!(isFocused || (typeof value === "string" && value?.length) || (typeof value !== "string" && value != null))
  , [field?.value, isFocused, value]);

  const handleChange = useCallback(
    e => {
      if (form) {
        form.setFieldValue(name, e.target.value);
      }
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(
    e => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
      if (field?.onBlur) {
        field.onBlur(e);
      }
    },
    [onBlur, field?.onBlur]
  );

  const handleFocus = useCallback(
    e => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
      if (field?.onFocus) {
        field.onFocus(e);
      }
    },
    [onFocus, field?.onFocus]
  );

  const endAdornmentContent = useMemo(() => {
    if (fieldTouched && error) {
      return (
        <WarningCircle
          weight="fill"
          size={26}
          color={theme.palette.new.red[1000]}
        />
      );
    } else {
      return null;
    }
  }, [fieldTouched, error]);

  return (
    <MUITextField
      variant={variant}
      {...field}
      label={label}
      value={field?.value || value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      helperText={fieldTouched && error}
      error={!!(fieldTouched && error)}
      select={select}
      classes={{
        root: classNames(
          classes.root,
          rounded && classes.rounded,
          icon && classes.withIcon,
          !label && classes.noLabel,
          select && classes.selectRoot
        ),
      }}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment>{icon}</InputAdornment>
        ) : (
          undefined
        ),
        endAdornment: (endAdornmentExternal || endAdornmentContent) && (
          <InputAdornment
            position="end"
            disablePointerEvents={!!select}
            classes={{ root: select && classes.endAdornmentForSelect }}
          >
            <Row align="center">
              {endAdornmentExternal}
              {endAdornmentContent}
            </Row>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink,
      }}
      SelectProps={{
        MenuProps: {
          classes: { paper: classes.menuPaper, list: classes.menuList },
        },
      }}
      {...rest}
    />
  );
};
