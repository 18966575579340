import { client } from "./client";

export const getBundles = async () => {
  const { data } = await client.get("clinics/bundles");
  return data.bundles;
};

export const getBundle = async (id) => {
  const { data } = await client.get(`clinics/bundles/${id}`);
  return data.bundle;
};

export const updateBundle = async (values) => {
  try {
    const { data } = await client.post(
      `clinics/bundles/${values.id}/update`,
      values
    );
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing bundle: ${err.response.data}`);
    return null;
  }
};
