import React, { useCallback } from "react";
import moment from "moment";

import { Column } from "components/layout";
import { Text } from "./new/Text";
import { DatePicker } from "./DatePicker";

export const DatePickerField = (props) => {
  const { field, form, label } = props;
  const { name } = field;

  const handleDayClicked = useCallback((day) => {
    const dateString = moment(day).format("YYYY-MM-DD");
    form.setFieldValue(name, dateString);
  }, []);

  return (
    <Column>
      {label && <Text>{label}</Text>}
      <DatePicker
        onDayClick={handleDayClicked}
        selectedDays={[moment(field.value).toDate()]}
      />
    </Column>
  );
};
