import React, { useEffect, useState } from "react";

import { getFacilityBundles } from "api/facilityBundles";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { FacilityBundlesList } from "components/facilityBundles/FacilityBundlesList";

export const FacilityBundlesPage = () => {
  const [facilityBundles, setFacilityBundles] = useState(null);
  console.log("bundles:", facilityBundles);

  useEffect(() => {
    getFacilityBundles().then(setFacilityBundles);
  }, []);

  if (!facilityBundles) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <FacilityBundlesList facilityBundles={facilityBundles} />
      </Column>
    </Page>
  );
};
