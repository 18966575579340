import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DataTable } from "components/tables/DataTable";

export const BundlesList = (props) => {
  const { bundles } = props;
  const history = useHistory();

  const filterFunction = (bundle, filter) =>
    bundle.id.toLowerCase().includes(filter) ||
    bundle.name.toLowerCase().includes(filter);

  const handleRowClicked = useCallback((bundle) => {
    history.push(`/bundles/${bundle.id}`);
  }, [history]);

  return (
    <DataTable
      title="Bundle"
      data={bundles}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
