import React from "react";
import { Form, Field, withFormik } from "formik";
import moment from "moment";

import { Column, Row } from "components/layout";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { TextField } from "components/ui/new/TextField";
import { DatePickerField } from "components/ui/DatePickerField";

export const AddSponsoredListingForm = withFormik({
  mapPropsToValues: () => ({
    facility_id: "",
    impressions_issued: 0,
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(() => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Extra Sponsored Listing Impressions
          </Text>
          <Row gap="36px">
            <Field
              component={TextField}
              name="facility_id"
              label="Facility ID"
            />
            <Field
              component={TextField}
              name="impressions_issued"
              label="Impressions Issued"
            />
          </Row>
          <Row gap="36px">
            <Field
              component={DatePickerField}
              name="start"
              label="Start Date"
            />
            <Field component={DatePickerField} name="end" label="End Date" />
          </Row>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
