import React, { useCallback, useMemo, useState } from "react";
import ReactDataTable from "react-data-table-component";

import { FilterComponent } from "./FilterComponent";
import { Button } from "components/ui/new/Button";

export const DataTable = (props) => {
  const {
    title,
    data,
    cellWidth,
    filterFunction,
    onRowClicked,
    onRowDeleteClicked,
    onRowActionButtonClicked,
    columnNames,
  } = props;

  const [firstItem] = data;

  const getColumnsFromFirstItem = useCallback(() => {
    if (!firstItem) {
      return ["id"];
    }
    return Object.keys(firstItem);
  }, [firstItem]);

  const getColumns = useCallback(() => {
    const rawColumns = columnNames || getColumnsFromFirstItem();
    const columns = rawColumns.map((key) => ({
      name: key,
      selector: (row) => row[key],
      width: cellWidth,
    }));

    if (onRowDeleteClicked) {
      columns.push({
        button: true,
        cell: (item) => (
          <Button
            variant="contained"
            color="danger"
            onClick={() => onRowDeleteClicked(item)}
          >
            Delete
          </Button>
        ),
      });
    }

    if (onRowActionButtonClicked) {
      columns.push({
        button: true,
        cell: (item) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onRowActionButtonClicked(item)}
          >
            Duplicate
          </Button>
        ),
      });
    }

    return columns;
  }, [
    columnNames,
    getColumnsFromFirstItem,
    onRowDeleteClicked,
    onRowActionButtonClicked,
    cellWidth,
  ]);

  const [filter, setFilter] = useState(null);
  const clearFilter = useCallback(() => {
    setFilter(null);
  }, []);
  const handleFilterChanged = useCallback((e) => {
    setFilter(e.target.value);
  }, []);
  const subHeaderComponent = (
    <FilterComponent onFilter={handleFilterChanged} clearFilter={clearFilter} />
  );

  const filteredSymptoms = useMemo(
    () => (filter ? data.filter((item) => filterFunction(item, filter)) : data),
    [data, filter, filterFunction]
  );

  return (
    <ReactDataTable
      title={title}
      columns={getColumns()}
      data={filteredSymptoms}
      subHeader
      subHeaderComponent={subHeaderComponent}
      pagination
      responsive
      onRowClicked={onRowClicked}
    />
  );
};
