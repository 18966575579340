import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";

export const AddFacilityFeatureOverrideForm = withFormik({
  mapPropsToValues: () => ({
    facility_id: "",
    feature_id: "",
  }),
  handleSubmit: (values, { props }) => {
    const formData = {
      facility_id: values?.facility_id?.trim() || null,
      feature_id: values?.feature_id?.trim() || null,
    };
    props.onSubmit(formData);
  },
})((props) => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Facility Feature Override
          </Text>
          <Row wrap gap="18px">
            <Field
              component={TextField}
              name="facility_id"
              label="facility_id"
            />
            <Field component={TextField} name="feature_id" label="feature_id" />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
            >
              Save
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
