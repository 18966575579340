import React, { useEffect, useState } from "react";

import { getBundles } from "api/bundles";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { BundlesList } from "components/bundles/BundlesList";

export const BundlesPage = () => {
  const [bundles, setBundles] = useState(null);

  useEffect(() => {
    getBundles().then(setBundles);
  }, []);

  if (!bundles) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <BundlesList bundles={bundles} />
      </Column>
    </Page>
  );
};
