import React from "react";
import { Form, Field, withFormik } from "formik";
import { MenuItem } from "@material-ui/core";
import moment from "moment";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { Select } from "components/ui/new/Select";
import { MultiSelect } from "components/ui/new/MultiSelect";
import { DatePickerField } from "components/ui/DatePickerField";
import { PromotionBar } from "./PromotionBar";
import provinceList from "../../provinceList.json";

const regionOptions = JSON.parse(provinceList).map(({ value, text }) => ({
  id: value,
  label: text,
}));

export const AddPromotionForm = withFormik({
  mapPropsToValues: () => ({
    promotion_type: "patient",
    regions: [],
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    enabled: 0,
    markdown: "",
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})((props) => {
  const { values } = props;
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Promotion
          </Text>
          <Row gap="12px">
            <Field
              component={Select}
              name="promotion_type"
              label="Promotion Type"
              style={{ width: "160px" }}
            >
              <MenuItem value="patient">Patient</MenuItem>
              <MenuItem value="clinic">Clinic</MenuItem>
            </Field>
            <Field
              component={MultiSelect}
              options={regionOptions}
              name="regions"
              label="Regions"
            />
            <Field
              component={Select}
              name="enabled"
              label="Status"
              style={{ width: "160px" }}
            >
              <MenuItem value={0}>Disabled</MenuItem>
              <MenuItem value={1}>Enabled</MenuItem>
            </Field>
          </Row>
          <Row gap="36px">
            <Field
              component={DatePickerField}
              name="start_date"
              label="Start Date"
            />
            <Field
              component={DatePickerField}
              name="end_date"
              label="End Date"
            />
          </Row>
          <Field component={TextField} name="markdown" label="Markdown" />
          <Column gap="4px">
            <Text>Preview:</Text>
            <PromotionBar promotion={values} />
          </Column>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ alignSelf: "flex-end" }}
          >
            Save
          </Button>
        </Column>
      </Form>
    </Column>
  );
});
