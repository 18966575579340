import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditPromotionForm } from "components/promotions/EditPromotionForm";

const getPromotion = async (id) => {
  const { data } = await client.get(`promotions/${id}`);
  return data.promotion;
};

const updatePromotion = async (values) => {
  try {
    const { data } = await client.post(`promotions/${values.id}/update`, values)
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing promotion: ${err.response.data}`);
  }
}

export const EditPromotionPage = () => {
  const [promotion, setPromotion] = useState(null);
  const { promotionId } = useParams();

  useEffect(() => {
    getPromotion(promotionId).then(setPromotion);
  }, [promotionId]);

  if (!promotion) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditPromotionForm promotion={promotion} onSubmit={updatePromotion} />
    </Page>
  );
};
