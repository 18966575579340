import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getAllVerticalContent } from "api/verticalContent";
import { Page } from "pages/Page";
import { Column } from "components/layout";
import { Button } from "components/ui/new/Button";
import { VerticalContentList } from "components/verticalContent/VerticalContentList";

export const VerticalContentPage = () => {
  const [allVerticalContent, setAllVerticalContent] = useState(null);

  useEffect(() => {
    getAllVerticalContent().then(setAllVerticalContent);
  }, []);

  if (!allVerticalContent) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <Column>
        <VerticalContentList allVerticalContent={allVerticalContent} />
        <Button
          component={Link}
          to="/verticalContent/add"
          variant="contained"
          color="primary"
        >
          Add Vertical Content
        </Button>
      </Column>
    </Page>
  );
};
