import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { DataTable } from "components/tables/DataTable";

export const SponsoredListingList = (props) => {
  const { sponsoredListings } = props;
  const history = useHistory();

  const filterFunction = (sponsoredListing, filter) =>
    sponsoredListing.id.toString().includes(filter) ||
    sponsoredListing.facility_id.toString().includes(filter);

  const handleRowClicked = useCallback(
    (sponsoredListing) => {
      history.push(`/sponsoredListings/${sponsoredListing.id}`);
    },
    [history]
  );

  const parsedListings = sponsoredListings.map((listing) => ({
    ...listing,
    start: moment(listing.start).format("YYYY-MM-DD"),
    end: moment(listing.end).format("YYYY-MM-DD"),
  }));

  return (
    <DataTable
      title="Sponsored Listings Additional Issued Impressions"
      data={parsedListings}
      filterFunction={filterFunction}
      onRowClicked={handleRowClicked}
    />
  );
};
