import React from "react";
import { Form, Field, withFormik } from "formik";
import { camelCase } from "lodash";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";

export const AddInsuranceProviderForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
  }),
  handleSubmit: (values, { props }) => {
    const trimmedName = values.name.trim();
    const formData = {
      name: trimmedName,
      id: trimmedName ? camelCase(trimmedName) : null,
    };
    props.onSubmit(formData);
  },
})(() => {
  return (
    <Column align="center">
      <Form>
        <Column gap="8px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            Add Insurance Provider
          </Text>
          <Row wrap gap="18px">
            <Field component={TextField} name="name" label="Name" />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
            >
              Save
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
