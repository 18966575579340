import React from "react";
import { Form, Field, withFormik } from "formik";

import { Column, Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { Button } from "components/ui/new/Button";
import { Text } from "components/ui/new/Text";
import { WysiwygEditor } from "components/misc/WysiwygEditor";

export const EditVerticalContentForm = withFormik({
  mapPropsToValues: ({ verticalContent }) => ({
    ...verticalContent,
  }),
  handleSubmit: (values, { props }) => {
    const { isDuplicate } = props;
    const formData = {
      ...values,
      app_location: values?.app_location?.trim() || "default",
      content_blurb: values?.content_blurb?.trim() || null,
    };
    props.onSubmit({ values: formData, isDuplicate });
  },
})((props) => {
  const { isDuplicate } = props;
  return (
    <Column style={{ padding: "20px 120px" }}>
      <Form>
        <Column gap="12px">
          <Text size="large" color="black100" style={{ marginBottom: "20px" }}>
            {isDuplicate ? "Duplicate" : "Edit"} Vertical Content
          </Text>
          <Row wrap gap="12px">
            <Field component={TextField} name="id" label="id" disabled />
            <Field
              component={TextField}
              name="facility_type"
              label="facility_type"
              disabled
            />
          </Row>
          <Row>
            <Field
              component={TextField}
              name="app_location"
              label="app_location"
              fullWidth
            />
          </Row>
          <Row>
            <Field
              component={WysiwygEditor}
              name="content_blurb"
              label="content_blurb"
            />
          </Row>
          <Row style={{ alignSelf: "flex-start", marginTop: "60px" }}>
            <Button type="submit" variant="contained" color="primary">
              {isDuplicate ? "Duplicate" : "Update"}
            </Button>
          </Row>
        </Column>
      </Form>
    </Column>
  );
});
