import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditFacilityFeatureOverrideForm } from "components/facilityFeatureOverrides/EditFacilityFeatureOverrideForm";

const getFacilityFeatureOverride = async (facilityId, featureId) => {
  const { data } = await client.get(
    `facilityFeatureOverrides/${facilityId}/${featureId}`
  );
  return data.facilityFeatureOverride;
};

const updateFacilityFeatureOverride = async (values) => {
  if (!values.feature_id) {
    window.alert(
      "Error editing facility feature override: feature_id is a reqired field"
    );
    return;
  }
  try {
    await client.post(`facilityFeatureOverrides/update`, values);
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing facility feature override: ${err.response.data}`);
  }
};

export const EditFacilityFeatureOverridePage = () => {
  const [facilityFeatureOverride, setFacilityFeatureOverride] = useState(null);
  const { facilityId, featureId } = useParams();

  useEffect(() => {
    getFacilityFeatureOverride(facilityId, featureId).then(
      setFacilityFeatureOverride
    );
  }, [facilityId, featureId]);

  if (!facilityFeatureOverride) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditFacilityFeatureOverrideForm
        facilityFeatureOverride={facilityFeatureOverride}
        onSubmit={updateFacilityFeatureOverride}
      />
    </Page>
  );
};
