import React from "react";

import { client } from "api";
import { Page } from "pages/Page";
import { AddFaqForm } from "components/faqs/AddFaqForm";

const addFaqItem = async (values) => {
  try {
    await client.post(`faqs/add`, values);
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    const errText = err.response.data.includes(
      "faq_content-facility_type-index-app_location"
    )
      ? `Duplicate entry already exists for facility_type = ${values.facility_type}, index = ${values.index} and app_location = ${values.app_location}`
      : err.response.data;
    window.alert(`Error adding FAQ: ${errText}`);
  }
};

export const AddFaqPage = () => {
  return (
    <Page>
      <AddFaqForm onSubmit={addFaqItem} />
    </Page>
  );
};
