import React, { useCallback } from "react";

import { client } from "api";
import { LoginForm } from "components/auth/LoginForm";

export const LoginPage = (props) => {
  const { onLogin } = props;

  const handleSubmit = useCallback(
    async (values) => {
      const { data } = await client.post("/login/external", values);
      const { idToken, refreshToken } = data;
      localStorage.setItem("authToken", idToken);
      localStorage.setItem("refreshToken", refreshToken);
      onLogin();
    },
    [onLogin]
  );

  return <LoginForm onSubmit={handleSubmit} />;
};
