import React, { useMemo, forwardRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { Row } from 'components/layout';

const mapSize = size => {
  switch (size) {
    case 'mediumLarge':
      return 'medium';
    default:
      return size;
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '999px',
    fontFamily: 'TTCommons, sans-serif',
    whiteSpace: 'nowrap',
    flexShrink: '0',
    '&.MuiButton-root:hover': {
      padding: ({ growOnHover }) => (growOnHover ? '18px 26px' : undefined),
      margin: ({ growOnHover }) => (growOnHover ? '-2px' : undefined),
    },
  },
  minimal: {
    backgroundColor: 'transparent',
    border: '0px solid transparent',
    padding: '0',
    borderRadius: '0',
    fontSize: '18px',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: 'transparent !important',
      textDecoration: 'underline',
    },
  },
  sizeSmall: {
    padding: '6px 12px',
  },
  square: {
    borderRadius: '16px',
  },
  sizeMedium: {
    height: '32px',
    fontSize: '16px',
    padding: '0 12px',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      height: '40px',
      fontSize: '18px',
      padding: '0 16px',
      '&$alwaysSmall': {
        height: '32px',
        fontSize: '16px',
        padding: '0 12px',
      },
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
    },
  },
  sizeMediumLarge: {
    height: '48px',
    fontSize: '20px',
    padding: '0 16px',
    fontWeight: 600,
    '& .MuiButton-startIcon': {
      marginRight: '8px',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '8px',
    },
  },
  sizeLarge: {
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 500,
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      lineHeight: '18px',
    },
    '&$alwaysSmall': {
      fontSize: '20px',
      lineHeight: '18px',
    },
    '&.MuiButton-outlined': {
      padding: '14px 24px',
    },
  },
  alwaysSmall: {},
  colorPrimary: {
    '&.MuiButton-contained': {
      color: 'white',
      backgroundColor: theme.palette.new.primary[1000],
      '&:hover': {
        backgroundColor: theme.palette.new.blue[1000],
        color: 'white',
      },
    },
  },
  colorSecondary: {
    '&.MuiButton-root': {
      color: theme.palette.new.black[100],
      backgroundColor: theme.palette.new.surface.secondary,
      '&:hover': {
        color: theme.palette.new.black[100],
        backgroundColor: theme.palette.new.surface.secondaryActive,
      },
    },
  },
  colorBlue: {
    '&.MuiButton-contained': {
      color: 'white',
      backgroundColor: theme.palette.new.blue[1000],
    },
    '&.MuiButton-contained:hover': {
      color: 'white',
      backgroundColor: theme.palette.new.blue.dark,
    },
    '&.MuiButton-outlined': {
      color: theme.palette.new.blue[1000],
      backgroundColor: 'white',
      borderColor: theme.palette.new.blue[1000],
      borderWidth: '2px',
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: theme.palette.new.surface.secondary,
      borderColor: theme.palette.new.blue.dark,
      color: theme.palette.new.blue.dark,
    },
    '&.MuiButton-text': {
      color: theme.palette.new.blue[1000],
      backgroundColor: 'transparent',
    },
    '&.MuiButton-text:hover': {
      backgroundColor: theme.palette.new.blue[200],
    },
  },
  colorBlue100: {
    color: theme.palette.new.blue[1000],
    backgroundColor: theme.palette.new.blue[100],
    '&:hover': {
      color: theme.palette.new.blue[1000],
      backgroundColor: theme.palette.new.blue[200],
    },
  },
  colorDanger: {
    '&.MuiButton-contained': {
      color: 'white',
      backgroundColor: theme.palette.new.red.dark,
    },
    '&.MuiButton-contained:hover': {
      color: 'white',
      backgroundColor: theme.palette.new.red.dark,
    },
    '&.MuiButton-outlined': {
      color: theme.palette.new.red[1000],
      backgroundColor: 'white',
      borderColor: theme.palette.new.red[1000],
      borderWidth: '2px',
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: theme.palette.new.surface.secondary,
      borderColor: theme.palette.new.red.dark,
      color: theme.palette.new.red.dark,
    },
    '&.MuiButton-text': {
      color: theme.palette.new.red[1000],
      backgroundColor: 'transparent',
    },
    '&.MuiButton-text:hover': {
      backgroundColor: theme.palette.new.red[200],
    },
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  childrenWhenLoading: {
    opacity: 0,
  },
}));

export const Button = forwardRef((props, ref) => {
  const {
    onClick,
    variant,
    color,
    size,
    style,
    children,
    className,
    minimal,
    alwaysSmall,
    square,
    loading,
    growOnHover: _growOnHover,
    ...rest
  } = props;
  const classes = useStyles(props);

  const sizeClass = useMemo(() => {
    if (!size) {
      return null;
    }
    switch (size) {
      case 'small':
        return classes.sizeSmall;
      case 'medium':
        return classes.sizeMedium;
      case 'mediumLarge':
        return classes.sizeMediumLarge;
      case 'large':
        return classes.sizeLarge;
      default:
        console.error('invalid size passed to Button component:', size);
        return classes.sizeMedium;
    }
  }, [size, classes]);

  const colorClass = useMemo(() => {
    if (!color) {
      return null;
    }
    switch (color) {
      case 'blue':
        return classes.colorBlue;
      case 'blue100':
        return classes.colorBlue100;
      case 'danger':
        return classes.colorDanger;
      case 'primary':
        return classes.colorPrimary;
      case 'secondary':
        return classes.colorSecondary;
      default:
        console.error('invalid color passed to Button component:', color);
        return null;
    }
  }, [color, classes]);

  return (
    <MuiButton
      variant={variant}
      onClick={onClick}
      classes={{ root: classes.root }}
      className={classNames(className, sizeClass, colorClass, {
        [classes.minimal]: minimal,
        [classes.alwaysSmall]: alwaysSmall,
        [classes.loading]: loading,
        [classes.square]: square,
      })}
      size={mapSize(size)}
      style={style}
      disableElevation
      ref={ref}
      {...rest}
    >
      {loading ? (
        <>
          <Row
            className={classes.loadingContainer}
            align="center"
            justify="center"
          >
            <img
              style={{ width: '60px' }}
              alt="Loading..."
              src="/images/common/loading-icon.gif"
              className={classes.loadingImage}
            />
          </Row>
          <span className={classes.childrenWhenLoading}>{children}</span>
        </>
      ) : (
        children
      )}
    </MuiButton>
  );
});
