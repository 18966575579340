import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { TextField } from './TextField';

export const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(show => !show);
  }, []);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
      {...props}
    />
  );
};
