import React, { useCallback } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DayPicker from "react-day-picker";
import IconButton from "@material-ui/core/IconButton";
import { CaretLeft, CaretRight } from "phosphor-react";
import classNames from "classnames";
import "react-day-picker/lib/style.css";

import { Row } from "components/layout/Row";

const useStyles = makeStyles((theme) => ({
  calendarNav: {
    position: "absolute",
    top: "12px",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: "70px",
      zIndex: "1000",
    },
  },

  calendarContainer: {
    borderColor: "rgba(0, 0, 0, 0.15)",
    borderStyle: "solid",
    borderWidth: "0px",
    fontFamily: "TTCommons, sans-serif",
    height: "max-content",
    "& .DayPicker-wrapper": {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      borderBottomWidth: "1px",
      display: "flex",
      paddingTop: "32px",
      "& .DayPicker": {
        width: "100%",
      },
      "& .DayPicker-wrapper": {
        display: "flex",
        flexDirection: "column",
        padding: 0,
      },
      "& .DayPicker-Month": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
      "& .DayPicker-Weekday, & .DayPicker-Day": {
        padding: 0,
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .DayPicker-Weekdays": {
        display: "flex",
        flexDirection: "column",
        marginTop: 0,
      },
      "& .DayPicker-WeekdaysRow, & .DayPicker-Week": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    "& .DayPicker-Month": {
      margin: 0,
    },
    "& .DayPicker-Day": {
      width: "36px",
      fontSize: "16px",
      color: theme.palette.new.black[100],
      borderRadius: "8px",
      "&:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
        backgroundColor: `${theme.palette.new.black[100]} !important`,
        color: "white",
        cursor: "pointer",
      },
      "&.DayPicker-Day--selected": {
        backgroundColor: `${theme.palette.new.black[100]} !important`,
        color: "white",
      },
      "&.DayPicker-Day--today": {
        color: `${theme.palette.new.blue[1000]} !important`,
      },
      "&.DayPicker-Day--disabled": {
        color: theme.palette.new.black[200],
        opacity: 0.5,
        textDecoration: "line-through",
      },
    },
    "& .DayPicker-Weekday": {
      fontSize: "16px",
      width: "36px",
      color: theme.palette.new.black[200],
    },
    "& .DayPicker-Caption": {
      marginBottom: "6px",
      marginTop: "20px",
    },
    "& .DayPicker-Caption>div": {
      color: "#1C1C1C",
      fontSize: "18px",
      fontWeight: 500,
    },
  },
}));

const CalendarNav = (props) => {
  const { onPreviousClick, onNextClick } = props;
  const theme = useTheme();
  const classes = useStyles();

  const handlePreviousClick = useCallback(() => {
    onPreviousClick();
  }, [onPreviousClick]);

  const handleNextClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);

  return (
    <Row className={classes.calendarNav}>
      <IconButton onClick={handlePreviousClick}>
        <CaretLeft size={16} color={theme.palette.new.black[200]} />
      </IconButton>
      <IconButton onClick={handleNextClick}>
        <CaretRight size={16} color={theme.palette.new.black[200]} />
      </IconButton>
    </Row>
  );
};

export const DatePicker = React.memo((props) => {
  const {
    onDayClick,
    onMonthChange,
    disabledDays = [],
    selectedDays = [],
    className,
    month = new Date(),
    initialMonth = new Date(),
  } = props;

  const classes = useStyles();

  return (
    <div className={classNames(classes.calendarContainer, className)}>
      <DayPicker
        initialMonth={initialMonth}
        month={month}
        disabledDays={disabledDays}
        selectedDays={selectedDays}
        onMonthChange={onMonthChange}
        onDayClick={onDayClick}
        navbarElement={CalendarNav}
      />
    </div>
  );
});
