import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { Page } from "pages/Page";
import { EditFaqForm } from "components/faqs/EditFaqForm";

const getFaqItem = async (faqId) => {
  const { data } = await client.get(`faqs/${faqId}`);
  return data.faq;
};

const updateFaqItem = async ({ values, isDuplicate }) => {
  if (!values.index || !values.question || !values.answer_body) {
    const missingField = !values.index
      ? "index"
      : !values.question
      ? "question"
      : "answer_body";
    window.alert(
      `Error ${
        isDuplicate ? "duplicating" : "editing"
      } FAQ: ${missingField} is a required field`
    );
    return;
  }
  try {
    if (isDuplicate) {
      await client.post(`faqs/add`, values);
    } else {
      await client.post(`faqs/${values.id}/update`, values);
    }
    window.alert("success");
    return;
  } catch (err) {
    console.error(err.response.data);
    const errText = err.response.data.includes(
      "faq_content-facility_type-index-app_location"
    )
      ? `Duplicate entry already exists for facility_type = ${values.facility_type}, index = ${values.index} and app_location = ${values.app_location}`
      : err.response.data;
    window.alert(
      `Error ${isDuplicate ? "duplicating" : "editing"} FAQ: ${errText}`
    );
  }
};

export const EditFaqPage = () => {
  const [faqItem, setFaqItem] = useState(null);
  const { faqId, duplicate } = useParams();

  const isDuplicate = duplicate === "duplicate";

  useEffect(() => {
    getFaqItem(faqId).then(setFaqItem);
  }, [faqId]);

  if (!faqItem) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditFaqForm
        faqItem={faqItem}
        isDuplicate={isDuplicate}
        onSubmit={updateFaqItem}
      />
    </Page>
  );
};
