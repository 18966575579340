import React, { useState, useCallback } from "react";
import { TextField } from "@material-ui/core";
import ReactDataTable from "react-data-table-component";

import { Page } from "pages/Page";
import { Row } from "components/layout";
import { Button } from "components/ui/new/Button";
import { getFacilityAudit } from "api/clinics";

const columnNames = [
  "facility_id",
  "endpoint",
  "user_id",
  "source",
  "timestamp",
];

const columns = columnNames.map((name) => ({
  name: name === "timestamp" ? "timestamp (PST)" : name,
  selector: (row) => row[name],
  sortable: true,
  wrap: true,
  width: "auto",
}));

const ExpandedRowComponent = ({ data }) => {
  const mappedRecord = Object.entries(data).reduce((acc, [key, value]) => {
    if (value === null || columnNames.includes(key)) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  const display = JSON.stringify(
    mappedRecord,
    (_, value) => {
      if (typeof value === "string") {
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
      return value;
    },
    2
  ).replace(/\\n|\\\\/g, ""); // remove double backslashes and newlines

  return <pre>{display}</pre>;
};

export const FacilityAuditPage = () => {
  const [facilityId, setFacilityId] = useState("");
  const [facilityAuditData, setFacilityAuditData] = useState(null);
  const [status, setStatus] = useState("editing");

  const onShowClicked = useCallback(async () => {
    if (!facilityId) {
      return;
    }
    setStatus("loading");
    try {
      const auditData = await getFacilityAudit(facilityId);
      setFacilityAuditData(auditData);
      setStatus("loaded");
    } catch (e) {
      console.error(e);
      setFacilityAuditData(null);
      setStatus("editing");
    }
  }, [facilityId]);

  return (
    <Page>
      <Row
        style={{
          display: "flex",
          maxWidth: "20em",
          justifyContent: "space-between",
          margin: "2em auto",
        }}
      >
        <TextField
          label="facility id"
          variant="outlined"
          value={facilityId}
          onChange={useCallback((e) => {
            setFacilityId(e.target.value);
            setStatus("editing");
          }, [])}
        />
        <Button
          color="primary"
          variant="contained"
          square
          onClick={onShowClicked}
          loading={status === "loading"}
        >
          Show Audit
        </Button>
      </Row>
      {status === "loaded" && (
        <ReactDataTable
          title={`Facility Audit for ${facilityId}`}
          columns={columns}
          data={facilityAuditData}
          pagination
          expandableRows
          expandableRowsComponent={ExpandedRowComponent}
        />
      )}
    </Page>
  );
};
