import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_ROOT;

export const client = axios.create({ baseURL: API_ROOT });

client.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
    config.headers["RefreshToken"] = refreshToken;
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.message.includes("401")) {
      localStorage.clear();
      window.location.reload();
    } else {
      throw err;
    }
  }
);
