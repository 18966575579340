import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client } from "api";
import { getFacilityTypes } from "api/facilityTypes";
import { Page } from "pages/Page";
import { EditSymptomForm } from "components/symptoms/EditSymptomForm";

const getSymptom = async (id) => {
  const { data } = await client.get(`symptoms/${id}`);
  return data.symptom;
};

const updateSymptom = async (values) => {
  try {
    const { data } = await client.post(`symptoms/${values.id}/update`, values)
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing symptom: ${err.response.data}`);
  }
}

export const EditSymptomPage = () => {
  const [symptom, setSymptom] = useState(null);
  const [facilityTypes, setFacilityTypes] = useState(null);
  const { symptomId } = useParams();

  useEffect(() => {
    getSymptom(symptomId).then(setSymptom);
  }, [symptomId]);
  useEffect(() => {
    getFacilityTypes().then(setFacilityTypes);
  }, []);

  if (!symptom || !facilityTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Page>
      <EditSymptomForm symptom={symptom} facilityTypes={facilityTypes} onSubmit={updateSymptom} />
    </Page>
  );
};
